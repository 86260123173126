<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="contact-info">
                        <span class="sub-title">Reset Password</span>
                        <h2>Get in Touch</h2>
                        <p>We'd love to hear from you! If you have any questions, feedback, or inquiries, please don't hesitate to reach out to us. Our dedicated team is here to assist you and provide any information you need.</p>
                        <ul>
                            <li>
                                <div class="icon">
                                    <a class="icon" href="https://goo.gl/maps/wmw2LiEUhFQqN3hg9"><i class='bx bx-map'></i></a>
                                </div>
                                <h3>Our Training Ground</h3>
                                <p><b>Atal Khel Parisar</b></p>
                                <p>236, Sector B, Slice 1, Aranya Nagar, Vijay Nagar, Indore, Madhya Pradesh 452010</p>
                                
                            </li>
                            <li>
                                <div class="icon">
                                    <a class="icon" href="https://goo.gl/maps/H3WLC7G7ha18KkBVA"><i class='bx bx-map'></i></a>
                                </div>
                                
                                <p><b>Nehru International Cricket Stadium</b></p>
                                <p>40, Big Nehru Stadium, Agra Bombay Road, Sanyogita Ganj, Indore - 452001</p>
                                
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                </div>
                                <h3>Contact</h3>
                                <p>Mobile: +91-78792-39123 </p>
                                <p>Mail: manishpacersarmy@gmail.com</p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-time-five'></i>
                                </div>
                                <h3>Hours of Training</h3>
                                <p>Monday - Saturday: Morning 6:00 am to 8:00 am</p>
                                <p>Sunday : 5:00 am to 8:00 am</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h2>Reset Your Password</h2>
                        <p>Enter New Password</p>
                        <form id="contactForm" @submit.prevent>
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <input v-model="password" type="password" name="passwod" id="password" placeholder="New Password">
                                    </div>
                                </div>                                
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit"  @click="resetPassword()" class="default-btn">Submit<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router'
import vueRecaptcha from 'vue3-recaptcha2';
import * as api from './../../apis/authapi.js';
import * as auth from './../../helpers/authHelper.js';
export default {
    name: 'Contact',
    data() {
            const router = useRouter();            
            const password = '';
            const userid = '';            
            return {
                router,                
                password,
                userid
            }
        },
    methods: {
        formSubmit() {
            console.log('form submitted');
        },
        resetPassword() {
                this.userid = this.$route.params.id;
                this.password = this.password;
                console.log('Lost Password form submitted');
                var ss=api.resetpassword(this.userid,this.password,this.router);
                console.log(ss);
            },
    },
}
</script>