<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Courses Grid 03" />
        <Courses />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Courses from '../CoursesThree/Courses'
import Footer from '../Layouts/Footer'

export default {
    name: 'CoursesPageThree',
    components: {
        Navbar,
        PageTitle,
        Courses,
        Footer,
    },
}
</script>