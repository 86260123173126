<template>
    <div class="gallery-area pt-100 pb-70">
        <div class="container">
            <div class="shorting-menu shorting-menu-style-two">
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'all'}" 
                    v-on:click="itemFilterKey = 'all'" 
                >
                    All
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'event'}"
                    v-on:click="itemFilterKey = 'event'"
                >
                    Event
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'training'}"
                    v-on:click="itemFilterKey = 'training'"
                >
                    Training
                </button>
            </div>
            <lightgallery
                :settings="{ plugins: plugins }"
                :onInit="onInit"
                :onBeforeSlide="onBeforeSlide"
                class="row"
            >
                <a
                    v-for="item in itemFilter"
                    :key="item.id"
                    :data-lg-size="item.size"
                    className="gallery-item"
                    :data-src="item.src"
                    class="col-lg-4 col-md-6 col-sm-6"
                > <div class="single-events-box">
                    <div class="single-gallery-item">
                        <div class="image">
                            <img className="img-responsive" :src="item.thumb" style="width: 100%;"/>
                        <span class="date">{{item.title}}</span>
                        </div>
                       
                    </div>
                    <!-- <div class="content">
                                <h3>
                                    <router-link to="/single-events">
                                        {{item.title}}
                                    </router-link>
                                </h3>
                                <span class="location">
                                    <i class="bx bx-map"></i>
                                    {{item.location}}
                                </span>
                            </div> -->
                        </div>
                    
                </a>
            </lightgallery>
        </div>
    </div>
</template>

<script>
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
// import styles from 'lightgallery/scss/lightgallery.scss';

import * as api from './../../apis/galleryapi';
let lightGallery= null;

export default {
    name: 'GalleryTwoColumn',
    components: {
        Lightgallery,
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    data: () => ({
        // return:{
            itemFilterKey: 'all',
            plugins: [lgThumbnail, lgZoom],
            items: [],
        // }
    }),
    beforeMount: async function(){
        this.items=await api.gallerylist();
        lightGallery.refresh();
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        event(){
            return this.items.filter((item) => item.key == 'Event' || item.key == 'event')
        },
        training(){
            return this.items.filter((item) => item.key == 'Training' || item.key == 'training')
        },
    },
   methods: {
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
            console.log('calling before slide');
        },
    },
};
</script>
<style lang="css">
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-zoom.css';
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
</style>