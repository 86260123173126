import router from "../router";

export const isLoggedIn = async () => {
  let Token_Name = localStorage.getItem('jwt')
  if (!Token_Name) {
    console.log("sss isLoggedIn");
    router.push({ name: 'AuthenticationPage' })
  }
}

export const isLoggedOut = async () => {
  let Token_Name = localStorage.getItem('jwt')
  console.log("Token Name-------------------",Token_Name);
  if (Token_Name) {
    console.log("sss isLoggedOut");
    // router.back()
    router.push({ name: 'UserDashboardPage' })
  }
}
