<template>
    <div>
        <Navbar />
        <PageTitle />
        <CoursesDetails />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../SingleCoursesOne/PageTitle'
import CoursesDetails from '../SingleCoursesOne/CoursesDetails'
import Footer from '../Layouts/Footer'

export default {
    name: 'SingleCoursesPageOne',
    components: {
        Navbar,
        PageTitle,
        CoursesDetails,
        Footer,
    },
}
</script>