<template>
    <div class="events-area pt-100 pb-70">
        <div class="container">
            <div class="shorting-menu shorting-menu-style-two">
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'all'}" 
                    v-on:click="itemFilterKey = 'all'" 
                >
                    All
                </button>
                <!-- <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'happening'}"
                    v-on:click="itemFilterKey = 'happening'"
                >
                    Development
                </button> -->
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'upcoming'}"
                    v-on:click="itemFilterKey = 'upcoming'"
                >
                    Upcoming
                </button>
                <button 
                    class="filter" 
                    :class="{active:itemFilterKey == 'expired'}"
                    v-on:click="itemFilterKey = 'expired'"
                >
                    Expired
                </button>
            </div>
            <div class="shorting">
                <div class="row">
                    <div 
                        class="col-lg-4 col-sm-6 col-md-6"
                        v-for="item in itemFilter"
                        :key="item.id"
                    >
                        <div class="single-events-box">
                            <div class="image">
                                <router-link :to="`/single-events/${item.id}`" class="d-block">
                                    <img :src="item.image" alt="image" style="width: 780px; height: 400px;">
                                </router-link>
                                <span class="date">{{item.date}}</span>
                            </div>
                            <div class="content">
                                <h3>
                                    <router-link :to="`/single-events/${item.id}`">
                                        {{item.title}}
                                    </router-link>
                                </h3>
                                <span class="location">
                                    <i class="bx bx-map"></i>
                                    {{item.location}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router'
import * as api from './../../apis/eventapi';
import * as auth from './../../helpers/authHelper.js';

export default defineComponent( {
    name: 'Running',
    data: function (){
        return {
            itemFilterKey: 'all',
            items:[],
        }
    },
    beforeMount: async function () {
        this.items=await api.eventlist();
        // this.items=[{
        //             id: 1,
        //             image: require('../../assets/img/events/img11.jpg'),
        //             date: 'Mon, 10 July, 2023',
        //             title: 'MPA 10K Challange',
        //             location: 'Nehru Stadium, Indore',
        //             key: 'upcoming',
        //         },
        //         {
        //             id: 2,
        //             image: require('../../assets/img/events/img2.jpg'),
        //             date: 'Tue, 19 May, 2021',
        //             title: 'International Conference ',
        //             location: 'Sydney, Australia',
        //             key: 'expired',
        //         },
        //         {
        //             id: 3,
        //             image: require('../../assets/img/events/img3.jpg'),
        //             date: 'Mon, 18 May, 2021',
        //             title: 'International Conference ',
        //             location: 'Istanbul, Turkey',
        //             key: 'expired',
        //         }];
        console.log("items before",this.items);
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        happening(){
            return this.items.filter((item) => item.key == 'happening')
        },
        upcoming(){
            return this.items.filter((item) => item.key == 'upcoming')
        },
        expired(){
            return this.items.filter((item) => item.key == 'expired')
        },
    },
    mounted() {
        console.log("items after",this.items);
        //auth.isLoggedIn();
        // this.getItems();
    },
    methods: {
            getItems(){
                // this.items=api.eventlist();
                // console.log("items",this.items);
            }
    },
});
</script>