<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-8 col-sm-8">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/img/logo2.png" alt="logo" style="width: 100px;">
                            <!-- <h3>Manish Pacer's Army</h3> -->
                        </router-link>
                        <p style="text-align: justify;text-justify: inter-word;">Welcome to <b>Manish Pacer's Army</b>, where passion meets sportsmanship! As a dedicated sports teacher, my mission is to cultivate a love for sports and instill valuable life skills in our students. With a strong emphasis on teamwork, discipline, and personal growth, we aim to create a nurturing environment that encourages athletes to push their boundaries and excel both on and off the field. Join our spirited community and let's unleash the power of sports together!</p>
                        <p>Follow us:</p>
                        <ul class="social-link">
                            
                            <li>
                                <a href="https://www.facebook.com/manish.goud.54?mibextid=LQQJ4d" class="d-block" target="_blank" aria-label="Read more about Manish goud">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/manish_pacers_army?utm_source=q" class="d-block" target="_blank" aria-label="Read more about Manish goud">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://youtube.com/@Athmanisgoud13" class="d-block" target="_blank" aria-label="Read more about Manish goud">
                                    <i class='bx bxl-youtube'></i>
                                </a>
                            </li>
                            <!-- <li>
                                <a href="https://whastsapp.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-whastsapp'></i>
                                </a>
                            </li> -->
                            <!-- <li>
                                <a href="https://twitter.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            
                            <li>
                                <a href="https://linkedin.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Explore</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/newsmedia">News & Media</router-link></li>
                            <li><router-link to="/gallery">Photo Gallery</router-link></li>
                            <!-- <li><router-link to="/running">Events</router-link></li> -->
                            <li><router-link to="/about-us">About Us</router-link></li>                                                     
                            <!-- <li><router-link to="/contact">Contact</router-link></li> -->
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Resources</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/success-story">Student Success</router-link></li>
                            <li><router-link to="/blog-one">Blog</router-link></li>
                            <li><router-link to="/events">Events</router-link></li>
                            <li><router-link to="/purchase-guide">Go Premium</router-link></li>
                            <li><router-link to="/advisor">Team Plans</router-link></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Training Grounds</h3>
                        <ul class="footer-contact-info">
                            <li>
                                <a href="https://goo.gl/maps/wmw2LiEUhFQqN3hg9" class="d-block" target="_blank">
                                    <i class='bx bx-map'></i>
                                    Atal Khel Parisar
                                </a>
                            </li>
                            <li>
                                <a href="https://goo.gl/maps/N9nd6q3cvhS8ppJx5" class="d-block" target="_blank">
                                    <i class='bx bx-map'></i>
                                    Nehru Stadium
                                </a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+44587154756">+1 (123) 456 7890</a>
                            </li>
                            <li>
                                <i class='bx bx-envelope'></i>
                                <a href="mailto:hello@vdemy.com">hello@mpa.com</a>
                            </li>
                            <li>
                                <i class='bx bxs-inbox'></i>
                                <a href="tel:+557854578964">+55 785 4578964</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p><i class='bx bx-copyright'></i>{{currentYear}} Manish Pacer's Army. All Rights Reserved.</p>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                            </li>
                            <!-- <li>
                                <router-link to="/terms-of-service">Terms & Conditions</router-link>
                            </li> -->
                            <li>
                                <router-link to="/refund-policy">Refund Policy</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>