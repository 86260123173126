<template>
    <div class="our-story-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="our-story-title">
                        <h3><span class="number">1</span> Inspirational stories are less about success</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="our-story-content">
                        <p>Manish Pacer’s Army began its journey officially on 17th March 2023. The training was started from June 2020 with least resources. Earlier the training was provided to the runners in the guidance of Manish Goud Sir and his other colleagues under the banner of Academy of Indore Marathoners(AIM) from last 10 years.</p>
                        <p>Since its inception, Manish Pacer's Army has been dedicated to promoting and fostering a culture of fitness, teamwork, and personal growth through the exhilarating sport of running. 
With each passing year, our organization has continued to evolve, achieving remarkable milestones and leaving an indelible impact on the running community.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="our-story-title">
                        <h3><span class="number">2</span> Achievement and Awards</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="our-story-content">
                        <p>In the year 2023, about 80 runners of Manish Pacers Army participated in Mumbai Marathon and all of them got their personal best time without any injuries and runner’s really enjoyed the 🏃‍♂️ run.</p>
                        <p>25 runners participated in the Delhi Half Marathon in the year 2022 MPA rocked everyone!
7 runners of Manish Pacers Army have also participated in the world famous All Tough Comrades Marathon and the success rate of all is 100%.!
</p>
<p>Runners of Manish Pacers Army have won laurels in India and abroad like Delhi, Mumbai, Ladakh, Hyderabad, Kolkata, Bangalore, Indore, Surat, Pachmari Hill Marathon, Satara Hill Marathon, and many more places…… </p>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="our-story-image">
                        <img src="../../assets/img/bg/story-img.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="our-story-title">
                        <h3><span class="number">3</span> A training for the Future</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="our-story-content">
                        <p>But the true measure of our success lies not just in the trophies and awards we have garnered, but in the lives we have touched and the positive change we have inspired. Manish Pacer's Army has been a catalyst for transformation, empowering individuals to lead healthier lifestyles, build unshakeable confidence, and embrace the joy of pushing their limits.</p>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="our-story-title">
                        <h3><span class="number">4</span> A success-oriented learning environment</h3>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="our-story-content">
                        <p>Together we can conquer new challenges, shatter existing records, and inspire generations to come.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurStory'
}
</script>