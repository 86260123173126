<template>
    <div class="terms-of-service-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="terms-of-service-content">
                        <!-- <img src="../../assets/img/courses/img2.jpg" alt="image"> -->
                        <!-- <p><i>This Terms of Service was last updated on January 1, 2021.</i></p> -->
                        <h3>The fees paid after joining the Manish Pacer’s Army is non-refundable.
                            Cancellation by Manish Pacer’s Army(MPA) of an event
                            </h3>
                        <p>Manish Pacer’s Army(MPA) reserves the right to cancel any or all events at any time before the commencement. In such an event where Manish Pacer’s Army(MPA) cancels a event before commencement, the participant will receive the fee refund in accordance with this policy within 20 working days of receipt of a valid refund request</p>
                        <h3> Cancellation by Participant for an event</h3>
                        <ol>
                            <li>A participant may either cancel his/her registration for a particular event or may opt to change the category of the registration within 10 days of registration, but such request should be made no later than fifteen (15) days of commencement of the relevant event. Additional fee may be payable for a revision in category as per applicable fee for the events and Terms and Conditions. Any excess fee on account of such revision shall be refunded per this policy.</li>
                            <li>Requests for refund of full amount of fees (application/registration fee) on account of cancellation of one’s enrolment for any event shall be considered only if the requests are made prior to 15 working days after the date of event commencement</li>
                            <li>In no other cases, Manish Pacer’s Army(MPA) shall be liable to refund any fee/payment made, for any event</li>
                            <li>The amount shall be refunded in the manner the registration fee has been paid (eg. credit card or same bank account) within two weeks of a receipt of a valid refund request and approval by Manish Pacer’s Army(MPA). No hard cash refund shall be made.</li>
                            <li>Any processing fee paid during the registration will not be part of the amount being refunded.</li>
                        </ol>
                        
                        <p>If the standard time-frame as mentioned above has passed and you have still not received the refund, please contact your credit or debit card issuer or your bank for more information. In some cases where the third-party service provider, for instance the card issuing company, levies a transaction charge for any charge / refund issued. The applicable charge is decided by such entity, we are not responsible for any such deductions from the refunded amount.
                            If you have any questions about our Refunds Policy or your expected refunds, please contact us at <a href="support@manishpacersarmy.in" style="color:blue;">support@manishpacersarmy.in</a> 
                            </p>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-12">
                    <SidebarWidget />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import SidebarWidget from '../Common/SidebarWidget'

export default {
    name: 'TermsService',
    components: {
        // SidebarWidget
    }
}
</script>