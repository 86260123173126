<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Courses Grid 01" />
        <Courses />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Courses from '../CoursesOne/Courses'
import Footer from '../Layouts/Footer'

export default {
    name: 'CoursesPageOne',
    components: {
        Navbar,
        PageTitle,
        Courses,
        Footer,
    },
}
</script>