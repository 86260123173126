<template>
  <div class="profile-authentication-area ptb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="register-form" style="width: 100%; margin: 0 auto">
            <!-- <h2>Register</h2> -->
            <form @submit.prevent>
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model="name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>DOB</label>
                    <input
                      v-model="dob_1"
                      type="date"
                      class="form-control"
                      placeholder="Date of birth"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Gender</label>
                    <input
                      v-model="gender"
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="f"
                    />
                    Female&nbsp;&nbsp;&nbsp;
                    <input
                      v-model="gender"
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="m"
                    />
                    Male
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Blood Group</label>
                    <!-- <input v-model="blood_group" type="text" class="form-control" placeholder="Blood Group"> -->
                    <select
                      v-model="blood_group"
                      class="form-select form-control"
                      style="margin-top: 9px"
                      required
                    >
                      <option>Select</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                  </div>
                </div>                
              </div>

              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Height</label>
                    <input
                      v-model="height"
                      type="text"
                      class="form-control"
                      placeholder="Height"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Weight</label>
                    <input
                      v-model="weight"
                      type="text"
                      class="form-control"
                      placeholder="Weight"
                      required
                    />
                  </div>
                </div>  
                 <div class="col-lg-3 col-md-12">
                  <label><b>Previous Race</b></label>
                  <select
                    v-model="previous_race"
                    class="form-select form-control"
                    style="margin-top: 9px"
                    required
                  >
                    <option value="1">5K</option>
                    <!-- <option class="form-control" selected>Race 1</option> -->
                    <option value="1">10K</option>
                    <option value="2">21K</option>
                    
                  </select>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Previous Race Time</label>
                    <input
                      v-model="previous_race_time"
                      type="text"
                      class="form-control"
                      placeholder="Race Time in seconds"
                      required
                    />
                  </div>
                </div>
              </div>
                <div class="row">
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea
                      v-model="address"
                      name=""
                      class="form-control"
                      cols="10"
                      rows="2"
                      required
                    ></textarea>
                    <!-- <input type="text" class="form-control" placeholder="Address"> -->
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>City</label>
                    <input
                      v-model="city"
                      type="text"
                      class="form-control"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      v-model="email"
                      type="Email"
                      class="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <label><b>Race Category</b></label>
                  <select
                    v-model="category"
                    class="form-select form-control"
                    style="margin-top: 9px"
                    required
                  >
                    <option value="1">Race 1</option>
                    <!-- <option class="form-control" selected>Race 1</option> -->
                    <option value="1">Race 2</option>
                    <option value="2">Race 3</option>
                    <option value="3">Race 4</option>
                  </select>
                </div>
              </div>
             
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>T-shirt size</label>
                    <input
                      v-model="tsirtSize"
                      type="text"
                      class="form-control"
                      placeholder="T-shirt size"
                      required
                    />
                  </div>
                </div>  
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Contact No</label>
                    <input
                      v-model="contact"
                      type="text"
                      class="form-control"
                      placeholder="Contact No"
                      required
                      @blur="acceptPhone(contact)" @keydown.enter.prevent="acceptPhone(contact)" @keydown.tab.prevent="acceptPhone(contact)"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Emergency Contact Name</label>
                    <input
                      v-model="e_contact_name"
                      type="text"
                      class="form-control"
                      placeholder="Emergency Contact No"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="form-group">
                    <label>Emergency Contact No</label>
                    <input
                      v-model="e_contact"
                      type="text"
                      class="form-control"
                      placeholder="Emergency Contact No"
                      required
                      @blur="acceptPhone2(e_contact)" @keydown.enter.prevent="acceptPhone2(e_contact)" @keydown.tab.prevent="acceptPhone2(e_contact)"
                    />
                  </div>
                </div>
              </div>
              <vue-recaptcha
                v-show="showRecaptcha"
                sitekey="6LfryVomAAAAAKS-OHMh8lUPL4CGSlMBXpCWQp6n"
                size="normal"
                theme="light"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha"
                required
              >
              </vue-recaptcha>
              <div class="row">
                  <div class="col-lg-3 col-md-12">
                      <button
                        style="width: 134px"
                        type="submit"
                        @click="formSubmitReg()"
                    >
                        Register
                    </button>
                  </div>
                  <div class="col-lg-6 col-md-12 alert">   
                     <b-alert fade :variant="alert_class_name" v-model="showAlert">
                          {{alertMessage}}
                      </b-alert>                               
                  </div>
              </div>
              <hr>
            
            </form>
            
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { useRouter } from "vue-router";
import vueRecaptcha from "vue3-recaptcha2";
import * as api from "./../../apis/eventapi.js";
import axios from 'axios';
import * as constant from '../../config/constant.js';
export default {
  name: "EventRegister",
  components: {
    vueRecaptcha,
  },
  data() {
    const router = useRouter();
    const event_id = this.$route.params.id;
    const name = "";
    const dob = "";
    const dob_1 = "";
    const gender = "";
    const address = "";
    const city = "";
    const email = "";
    const blood_group = "";
    const category = "";
    const tsirtSize = "";
    const contact = "";
    const e_contact = "";
    const e_contact_name = "";
    const height = "";
    const weight = "";
    const previous_race = "";
    const previous_race_time = "";
    return {
      router,
      event_id,
      name,
      dob,
      dob_1,
      gender,
      address,
      city,
      email,
      blood_group,
      category,
      tsirtSize,
      contact,
      e_contact,
      e_contact_name,
      isRecaptchaVerified: false,
      isRecaptchaExpired: false,
      isRecaptchaFailed: true,
      showRecaptcha: true,
      height,
      weight,
      previous_race,
      previous_race_time,            
      alert_class_name: "",      
      showAlert: false,
      alertMessage: 'This is the alert message',
      requiredFieds: null,
      requiredMsg: null,
      reuField:"",

    };
  },
  watch: {
    name(newValue) {
      console.log(newValue);
    },
    dob_1(myDate) {
      console.log(myDate);
      this.dob = moment(myDate).format("YYYY-MM-DD");
    },
    gender(newValue) {
      console.log(newValue);
    },
    address(newValue) {
      console.log(newValue);
    },
    city(newValue) {
      console.log(newValue);
    },
    email(newValue) {
      console.log(newValue);
    },
    blood_group(newValue) {
      console.log(newValue);
    },
    category(newValue) {
      console.log(newValue);
    },
    tsirtSize(newValue) {
      console.log(newValue);
    },
    contact(newValue) {
      console.log(newValue);
    },
    e_contact(newValue) {
      console.log(newValue);
    },
    e_contact_name(newValue) {
      console.log(newValue);
    },
    height(newValue) {
      console.log(newValue);
    },
    weight(newValue) {
      console.log(newValue);
    },
    previous_race(newValue) {
      console.log(newValue);
    },
    previous_race_time(newValue) {
      console.log(newValue);
    },
  },
  methods: {
    acceptPhone(val) {
     if (val) {
                var x = val.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.contact = !x[2] ? x[1] :  + x[1] +  x[2] + x[3];
                this.contact = this.contact;
            }
    },
    acceptPhone2(val) {
     if (val) {
                var x = val.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.e_contact = !x[2] ? x[1] :  + x[1] +  x[2] + x[3];
                this.e_contact = this.e_contact;
            }
    },    
    delayedAlert() {     
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
    async formSubmitReg() {
      var event_id = this.$route.params.id;
      this.requiredFieds = 0;
      if (this.name == null || this.name == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.dob == null || this.dob == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.gender == null || this.gender == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.address == null || this.address == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.city == null || this.city == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.email == null || this.email == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.blood_group == null || this.blood_group == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.category == null || this.category == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.tsirtSize == null || this.tsirtSize == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.contact == null || this.contact == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.e_contact == null || this.e_contact == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.e_contact_name == null || this.e_contact_name == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.height == null || this.height == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.weight == null || this.weight == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.previous_race == null || this.previous_race == '' ){
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }
      if (this.previous_race_time == null || this.previous_race_time == ''  ) {
        this.requiredFieds = 1;
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }

      if (this.requiredFieds == 1) {
        this.delayedAlert();
        this.alertMessage = "All Fields Are Required.";
        this.alert_class_name = "danger";
      }else{
        var data = {
        event_id,
        name: this.name,
        dob: this.dob,
        gender: this.gender,
        address: this.address,
        city: this.city,
        email: this.email,
        blood_group: this.blood_group,
        category: this.category,
        tsirtSize: this.tsirtSize,
        contact: this.contact,
        e_contact: this.e_contact,
        e_contact_name: this.e_contact_name,
        height: this.height,
        weight: this.weight,
        previous_race: this.previous_race,
        previous_race_time: this.previous_race_time
      };
      
      console.log(data);
      var response =await api.eventreg(data, this.router);
        console.log(response);
        if (response.status == true) {
         // alert(response.data.msg)          
          console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
          this.alert_class_name = "success"; 
       
        } else {
         // alert(response.data.msg)          
          console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
          this.alert_class_name = "danger";
        
        }
      }
      
      
    },
    recaptchaVerified(response) {
      this.isRecaptchaVerified = true;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = false;
      console.log(response);
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = true;
      this.isRecaptchaFailed = false;
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaFailed() {
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = true;
      console.log("response");
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    isCaptcha() {
      if (
        this.isRecaptchaVerified &&
        !this.isRecaptchaExpired &&
        !this.isRecaptchaFailed
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.alert {
  top: 3px;
}
</style>