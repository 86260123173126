<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Events" />
        <Events />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Events from '../Events/Events'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'EventsPage',
    components: {
        Navbar,
        PageTitle,
        Events,
        Subscribe,
        Footer,
    },
}
</script>