<template>
    <div>
        <Navbar />
        <PageTitle :pageTitle="products.name" />
        <ProductsDetails 
            :id="products.id"
            :item="products"
            :name="products.name" 
            :price="products.price"
            :oldPrice="products.oldPrice"
            :image="products.image"
            :sale="products.sale"
            :new="products.new"
        />
        <RelatedProducts />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import ProductsDetails from '../ProductsDetails/ProductsDetails'
import RelatedProducts from '../ProductsDetails/RelatedProducts'
import Footer from '../Layouts/Footer'

export default {
    name: 'CartPage',
    components: {
        Navbar,
        PageTitle,
        ProductsDetails,
        RelatedProducts,
        Footer,
    },
    data() {
        return {
            id: this.$route.params.id
        }
    },
    computed: {
        products(){
            return this.$store.state.items.find(items => items.id == this.id);
        }
    }
}
</script>