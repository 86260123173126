<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Courses List 01" />
        <CoursesList />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import CoursesList from '../CoursesFive/CoursesList'
import Footer from '../Layouts/Footer'

export default {
    name: 'CoursesPageFour',
    components: {
        Navbar,
        PageTitle,
        CoursesList,
        Footer,
    },
}
</script>