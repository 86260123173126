<template>
    <div class="advisor-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img4.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">James Andy</a></h3>
                            <span>Project Management Expert</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img5.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">Jassica Hische</a></h3>
                            <span>Illustrator Expert</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img6.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">Alister Cock</a></h3>
                            <span>QA Project Expert</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img7.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">Lina Ninja</a></h3>
                            <span>QA Project Expert</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img8.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">Chris Evans</a></h3>
                            <span>Python Expert</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-advisor-item">
                        <div class="advisor-image">
                            <img src="../../assets/img/advisor/img9.jpg" alt="image">
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="advisor-content">
                            <h3><a href="#">Scarlett Johansson</a></h3>
                            <span>Photoshop Expert</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Advisor'
}
</script>