<template>
    <div class="partner-area">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img1.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img2.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img3.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img4.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img5.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Partner'
}
</script>