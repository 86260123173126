<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><router-link to="/">Home</router-link></li>
                    <li><a href="#">Single Courses</a></li>
                    <li>Running Course</li>
                </ul>
                <h2>Single Courses One</h2>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <div class="rating-count">
                        <span>4.0 (1 rating)</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape9">
            <img src="../../assets/img/shape/shape8.svg" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>