import { createWebHistory, createRouter } from "vue-router";
import HomePages from "../components/Pages/HomePages";

import AboutPageOne from "../components/Pages/AboutPageOne";
import AboutPageTwo from "../components/Pages/AboutPageTwo";
import AboutPageThree from "../components/Pages/AboutPageThree";
import AboutPageFour from "../components/Pages/AboutPageFour";
import AboutPageFive from "../components/Pages/AboutPageFive";
import SuccessStoryPage from "../components/Pages/SuccessStoryPage";
import AdvisorPage from "../components/Pages/AdvisorPage";
import TrainerPage from "../components/Pages/TrainerPage";
import GalleryPage from "../components/Pages/GalleryPage";
import FaqPage from "../components/Pages/FaqPage";
import ContactPage from "../components/Pages/ContactPage";
import AuthenticationPage from "../components/Pages/AuthenticationPage";
import EventRegisterPage from "../components/Pages/EventRegisterPage";
import UserRegisterPage from "../components/Pages/UserRegisterPage";
// import EmailVerificationPage from "../components/Pages/EmailVerificationPage";
// import EmailSuccessPage from "../components/Pages/EmailSuccessPage";
import UserDashboardPage from "../components/Pages/UserDashboardPage";
import UserProfilePage from "../components/Pages/UserProfilePage";
import ErrorPage from "../components/Pages/ErrorPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import EventGuidePage from "../components/Pages/EventGuidePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsServicePage from "../components/Pages/TermsServicePage";
import RefundPolicyPage from "../components/Pages/RefundPolicyPage";
import CoursesPageOne from "../components/Pages/CoursesPageOne";
import CoursesPageTwo from "../components/Pages/CoursesPageTwo";
import CoursesPageThree from "../components/Pages/CoursesPageThree";
import CoursesPageFour from "../components/Pages/CoursesPageFour";
import CoursesPageFive from "../components/Pages/CoursesPageFive";
import CoursesPageSix from "../components/Pages/CoursesPageSix";
import SingleCoursesPageOne from "../components/Pages/SingleCoursesPageOne";
import SingleCoursesPageTwo from "../components/Pages/SingleCoursesPageTwo";
import CategoriesPage from "../components/Pages/CategoriesPage";
import MembershipLevelsPage from "../components/Pages/MembershipLevelsPage";
// import BecomeTeacherPage from "../components/Pages/BecomeTeacherPage";
import ProfilePage from "../components/Pages/ProfilePage";
import EventsPage from "../components/Pages/EventsPage";
import RunningPage from "../components/Pages/RunningPage";
import NewsMediaPage from "../components/Pages/NewsMediaPage";
import EventsDetailsPage from "../components/Pages/EventsDetailsPage";
import BlogPageOne from "../components/Pages/BlogPageOne";
import BlogPageTwo from "../components/Pages/BlogPageTwo";
import BlogPageThree from "../components/Pages/BlogPageThree";
import BlogPageFour from "../components/Pages/BlogPageFour";
import BlogDetailsPageOne from "../components/Pages/BlogDetailsPageOne";
import BlogDetailsPageTwo from "../components/Pages/BlogDetailsPageTwo";
import BlogDetailsPageThree from "../components/Pages/BlogDetailsPageThree";
import ShopPageOne from "../components/Pages/ShopPageOne";
import ShopPageTwo from "../components/Pages/ShopPageTwo";
import CartPage from "../components/Pages/CartPage";
import CheckoutPage from "../components/Pages/CheckoutPage";
import ProductsDetailsPage from "../components/Pages/ProductsDetailsPage";
import LostPassword from "../components/Pages/ForgotPassword";
import EmailVerification from "../components/Pages/EmailVerification";

const routes = [
  {
    path: "/", name: "HomePages", meta: {
      allowAnonymous: true,
    }, component: HomePages
  },

  { path: "/about-us", name: "AboutPageOne", component: AboutPageOne },
  { path: "/about-two", name: "AboutPageTwo", component: AboutPageTwo },
  { path: "/about-three", name: "AboutPageThree", component: AboutPageThree },
  { path: "/about-four", name: "AboutPageFour", component: AboutPageFour },
  { path: "/about-five", name: "AboutPageFive", component: AboutPageFive },
  {
    path: "/success-story",
    name: "SuccessStoryPage",
    meta: {
      allowAnonymous: true,
    }, component: SuccessStoryPage,
  },
  {
    path: "/advisor", name: "AdvisorPage", meta: {
      allowAnonymous: true,
    }, component: AdvisorPage
  },
  {
    path: "/trainer", name: "TrainerPage", meta: {
      allowAnonymous: true,
    }, component: TrainerPage
  },
  {
    path: "/gallery", name: "GalleryPage", meta: {
      allowAnonymous: true,
    }, component: GalleryPage
  },
  {
    path: "/faq", name: "FaqPage", meta: {
      allowAnonymous: true,
    }, component: FaqPage
  },
  {
    path: "/contact", name: "ContactPage", meta: {
      allowAnonymous: true,
    }, component: ContactPage
  },
  {
    path: "/lostPassword/:id", name: "LostPassword", meta: {
      allowAnonymous: true,
    }, component: LostPassword
  },
  {
    path: "/emailVerification/:id", name: "EmailVerification", meta: {
      allowAnonymous: true,
    }, component: EmailVerification
  },

  


  {
    path: "/login",
    name: "AuthenticationPage",
    meta: {
      allowAnonymous: true,
    }, component: AuthenticationPage,
  },
  {
    path: "/eventRegister/:id",
    name: "EventRegisterPage",
    meta: {
      allowAnonymous: true,
    }, component: EventRegisterPage,
  },
  {
    path: "/signup",
    name: "UserRegisterPage",
    meta: {
      allowAnonymous: true,
    }, component: UserRegisterPage,
  },  
  // {
  //   path: "/emailVerification",
  //   name: "EmailVerificationPage",
  //   meta: {
  //       allowAnonymous: true,
  //     }, component: EmailVerificationPage,
  // },
  {
    path: "/emailSuccess",
    name: "Email",
    meta: {
      allowAnonymous: true,
    }, component: UserRegisterPage,
  },
  {
    path: "/dashboard",
    name: "UserDashboardPage",
    component: UserDashboardPage,
  },
  {
    path: "/profile",
    name: "UserProfilePage",
    component: UserProfilePage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  {
    path: "/event-guide",
    name: "EventGuidePage",
    meta: {
      allowAnonymous: true,
    }, component: EventGuidePage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    meta: {
      allowAnonymous: true,
    }, component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-service",
    name: "TermsServicePage",
    meta: {
      allowAnonymous: true,
    }, component: TermsServicePage,
  },
  {
    path: "/refund-policy",
    name: "RefundPolicyPage",
    meta: {
      allowAnonymous: true,
    }, component: RefundPolicyPage,
  },
  {
    path: "/courses-one", name: "CoursesPageOne", meta: {
      allowAnonymous: true,
    }, component: CoursesPageOne
  },
  {
    path: "/courses-two", name: "CoursesPageTwo", meta: {
      allowAnonymous: true,
    }, component: CoursesPageTwo
  },
  {
    path: "/courses-three",
    name: "CoursesPageThree",
    meta: {
      allowAnonymous: true,
    }, component: CoursesPageThree,
  },
  {
    path: "/courses-four",
    name: "CoursesPageFour",
    meta: {
      allowAnonymous: true,
    }, component: CoursesPageFour,
  },
  {
    path: "/courses-five",
    name: "CoursesPageFive",
    meta: {
      allowAnonymous: true,
    }, component: CoursesPageFive,
  },
  {
    path: "/courses-six", name: "CoursesPageSix", meta: {
      allowAnonymous: true,
    }, component: CoursesPageSix
  },
  {
    path: "/single-courses-one",
    name: "SingleCoursesPageOne",
    meta: {
      allowAnonymous: true,
    }, component: SingleCoursesPageOne,
  },
  {
    path: "/join",
    name: "SingleCoursesPageTwo",
    meta: {
      allowAnonymous: true,
    }, component: SingleCoursesPageTwo,
  },
  {
    path: "/categories", name: "CategoriesPage", meta: {
      allowAnonymous: true,
    }, component: CategoriesPage
  },
  {
    path: "/become-a-memeber",
    name: "MembershipLevelsPage",
    meta: {
      allowAnonymous: true,
    }, component: MembershipLevelsPage,
  },
  // {
  //   path: "/become-a-teacher",
  //   name: "BecomeTeacherPage",
  //   component: BecomeTeacherPage,
  // },
  { path: "/profile", name: "ProfilePage", component: ProfilePage },
  { path: "/events", name: "EventsPage", component: EventsPage },
  { path: "/running", name: "RunningPage", component: RunningPage },
  { path: "/newsmedia", name: "NewsMediaPage", component: NewsMediaPage },
  {
    path: "/single-events/:id",
    name: "EventsDetailsPage",
    meta: {
      allowAnonymous: true,
    }, component: EventsDetailsPage,
  },
  {
    path: "/blog-one", name: "BlogPageOne", meta: {
      allowAnonymous: true,
    }, component: BlogPageOne
  },
  {
    path: "/blog-two", name: "BlogPageTwo", meta: {
      allowAnonymous: true,
    }, component: BlogPageTwo
  },
  {
    path: "/blog-three", name: "BlogPageThree", meta: {
      allowAnonymous: true,
    }, component: BlogPageThree
  },
  {
    path: "/blog-four", name: "BlogPageFour", meta: {
      allowAnonymous: true,
    }, component: BlogPageFour
  },
  {
    path: "/single-blog-one",
    name: "BlogDetailsOne",
    meta: {
      allowAnonymous: true,
    }, component: BlogDetailsPageOne,
  },
  {
    path: "/single-blog-two",
    name: "BlogDetailsTwo",
    meta: {
      allowAnonymous: true,
    }, component: BlogDetailsPageTwo,
  },
  {
    path: "/single-blog-three",
    name: "BlogDetailsThree",
    meta: {
      allowAnonymous: true,
    }, component: BlogDetailsPageThree,
  },
  {
    path: "/products-list-one", name: "ShopPageOne", meta: {
      allowAnonymous: true,
    }, component: ShopPageOne
  },
  {
    path: "/products-list-two", name: "ShopPageTwo", meta: {
      allowAnonymous: true,
    }, component: ShopPageTwo
  },
  {
    path: "/cart", name: "CartPage", meta: {
      allowAnonymous: true,
    }, component: CartPage
  },
  {
    path: "/checkout", name: "CheckoutPage", meta: {
      allowAnonymous: true,
    }, component: CheckoutPage
  },
  {
    path: "/single-products/:id",
    name: "ProductsDetailsPage",
    meta: {
      allowAnonymous: true,
    }, component: ProductsDetailsPage,
  },
];

// Vue.use(Router);
// export default new Router({
//   mode: 'history',
//   history: createWebHistory(),
//   routes,
//   scrollBehavior() {
//         return { top: 0, behavior: "smooth" };
//       },

// }); 

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },

});

export default router;