<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <OnlineCoaching />
        <ProjectManagementSkills />
        <Feedback />
        <MeetOurInstructors class="pb-100" />
        <Funfacts />
        <PotentialUnlimitedAccess />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import OnlineCoaching from '../AboutThree/OnlineCoaching'
import ProjectManagementSkills from '../AboutThree/ProjectManagementSkills'
import Feedback from '../AboutThree/Feedback'
import MeetOurInstructors from '../Common/MeetOurInstructors'
import Funfacts from '../AboutThree/Funfacts'
import PotentialUnlimitedAccess from '../Common/PotentialUnlimitedAccess'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageThree',
    components: {
        Navbar,
        PageTitle,
        OnlineCoaching,
        ProjectManagementSkills,
        Feedback,
        MeetOurInstructors,
        Funfacts,
        PotentialUnlimitedAccess,
        Footer,
    },
}
</script>