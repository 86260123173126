<template>
    <div class="events-details-area pb-100">
        <div class="events-details-image">
            <img src="../../assets/img/events/events-details.jpg" alt="image">
            <div id="timer" class="flex-wrap d-flex justify-content-center">
                <div 
                    id="days" 
                    class="align-items-center flex-column d-flex justify-content-center"
                >
                    <h3>{{days}}</h3>
                    <p>Days</p>
                </div>
                <div 
                    id="hours" 
                    class="align-items-center flex-column d-flex justify-content-center"
                >
                    <h3>{{hours}}</h3>
                    <p>Hours</p>
                </div>
                <div 
                    id="minutes" 
                    class="align-items-center flex-column d-flex justify-content-center"
                >
                    <h3>{{minutes}}</h3>
                    <p>Minutes</p>
                </div>
                <div 
                    id="seconds" 
                    class="align-items-center flex-column d-flex justify-content-center"
                >
                    <h3>{{seconds}}</h3>
                    <p>Seconds</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="events-details-header">
                        <ul>
                            <li><i class='bx bx-calendar'></i>{{ date}}</li>
                            <li><i class='bx bx-map'></i>{{place}}</li>
                            <li><i class='bx bx-time'></i>10:00 Am - 12:00 AM </li>
                        </ul>
                    </div>
                    <div class="events-details-location">
                        <div id="map">
                            <!-- <iframe width="770" height="510" id="gmap_canvas" src="https://maps.google.com/maps?q=indore&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> -->

                             <!-- <iframe src = "https://maps.google.com/maps?q=22.7196,75.8577&hl=es;z=14&amp;output=embed"></iframe>    -->

                            <iframe 
                                width="300" 
                                height="170" 
                                frameborder="0" 
                                scrolling="no" 
                                marginheight="0" 
                                marginwidth="0" 
                                :src="google_map"
                                >
                            </iframe>    
                            
                        </div>
                    </div>
                    <div class="events-details-desc">
                        <h3>About The Event</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <h3>Where the event?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <h3>Who this event is for?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="events-details-info">
                        <ul class="info">
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Cost</span>
                                    &#x20B9; {{fee}}
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Total Slot</span>
                                    1500
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Booked Slot</span>
                                    350
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Pay With</span>
                                    <div class="payment-method">
                                        <img src="../../assets/img/payment/img1.png" class="shadow" alt="image">
                                        <img src="../../assets/img/payment/img2.png" class="shadow" alt="image">
                                        <img src="../../assets/img/payment/img3.png" class="shadow" alt="image">
                                        <img src="../../assets/img/payment/img4.png" class="shadow" alt="image">
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-box">
                            <router-link :to="`/eventRegister/${event_id}`" class="default-btn"> <i class="flaticon-user"></i>Register Now<span></span></router-link> 
                            <p>You must <router-link to="/authentication">Login</router-link> before register event.</p>
                        </div>
                        <div class="events-share">
                            <div class="share-info">
                                <span>Share This Course <i class="flaticon-share"></i></span>
                                <ul class="social-link">
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router/index.js';
import * as api from "./../../apis/eventapi.js";
import moment from "moment";
export default {
    name: 'EventsDetails',
    data() {
        const event_id = this.$route.params.id;
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            event_id,
            place:'',
            date:'',
            event_time:'',
            fee:'',
            google_map:'https://maps.google.com/maps?q=22.7196,75.8577&hl=es;z=14&output=embed',
            latitude:'22.7196',
            longitude:'75.8577',
            commingSoonDate:'',
            commingSoonevent_time:'',

        }
    },
    created() { // Turn data into viewable values
        setInterval(() => {
            this.commingSoonTime();
        }, 1000);
    },
    mounted(){
    this.getEvent();
  },
    methods: {        
        commingSoonTime() {
            //let commingdate = this.commingSoonDate+" 17:00:00 PDT";
            let endTime = new Date(this.commingSoonDate+" "+this.commingSoonevent_time+" PDT");			
            let endTimeParse = (Date.parse(endTime)) / 1000;
            let now = new Date();
            let nowParse = (Date.parse(now) / 1000);
            let timeLeft = endTimeParse - nowParse;
            let days = Math.floor(timeLeft / 86400); 
            let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
            let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        },
       async getEvent(){
        var data = {
            event_id:this.$route.params.id,        
        };
        var response =await api.eventbyid(data, this.router);
            console.log(response.data);
             if(response.data.status==true && response.data.msg=="Event Details Found" ){
                 this.place = response.data.data.place;
                 this.date =  moment(response.data.data.date_time).format("dddd, MMMM Do, YYYY");
                 this.commingSoonDate =  moment(response.data.data.date_time).format("MMMM DD, YYYY");
                 this.commingSoonevent_time = response.data.data.event_time;

                 this.event_time = moment(response.data.data.event_time).format("h:mm a");
                 this.fee = response.data.data.fee;
                 this.latitude = response.data.data.latitude;
                 this.longitude = response.data.data.longitude;  
                 if(this.latitude!='' && this.longitude!=''){
                     this.google_map = "https://maps.google.com/maps?q="+this.latitude+","+this.longitude+"&hl=es&z=14&output=embed";
                 }else{
                      this.google_map = 'https://maps.google.com/maps?q=22.7196,75.8577&hl=es;z=14&output=embed';
                 }
                                 
             }else{
                 router.push({ path: '/running' })
             }
        }

    }
}
</script>