<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="privacy-policy-content">
                        <img src="../../assets/img/courses/runImage.png" alt="image">
                        <p><i>This Privacy Policy was last updated on July 1, 2023.</i></p>
                        <p>At Manish Pacer’s Army, we understand the importance of privacy and the sensitivity of personal information, especially when it comes to activity data through Garmin device. We are committed to protecting Garmin users' personal information and providing transparency on how it is used and disclose their activity data. This privacy statement explains how we collect, use, and disclose activity data.</p>
                        <h3>1. Collection of activity data</h3>
                       <p>We collect activity data for training plan application, including garmin smartwatches, mobile applications, and websites. This data includes information about your physical activities, such as steps taken, distance travelled, calories burned, heart rate, and sleep patterns. We also collect location data, such as GPS coordinates, to track your activity.</p>
                        <h3>2. Use of activity data</h3>
                        <p>We use activity data to improve training plan, provide personalized recommendations and insights, and support research and development. We may also use this data to provide user support and troubleshoot issues. Your data may be used in an anonymized and aggregated form to generate insights and trends.</p>
                        <h3>3. Disclosure of activity data</h3>
                        <p>We will not disclose activity data to any third-party service providers. For example, we may share data with cloud hosting providers. We may also disclose data to government authorities or law enforcement if required by law or if we believe in good faith that such disclosure is necessary to protect our rights, the safety of our users, or the public.</p>
                        <h3>4. Data retention and deletion</h3>
                 <p>We retain activity data for as long as necessary to provide training plan application or for other essential purposes, such as complying with legal obligations. When you delete your account, we will delete your activity data within a reasonable time, subject to our data retention policies.</p>
                        <h3>5. Your rights</h3>
                        <p>You have the right to access, correct, or delete your activity data. You can also request that we restrict or object to the processing of your data, or request a copy of your data in a structured, commonly used, and machine-readable format. Please contact us at privacy@ manishpacersarmy.com to exercise your rights..</p>
                       
                        <h3>6. Security</h3>
                        <p>We take appropriate technical and organizational measures to protect activity data against unauthorized or unlawful processing and against accidental loss, destruction, or damage. We regularly review and update our security measures to ensure that your data is protected.</p>
                        <h3>7. Changes to this policy</h3>
                        <p>We may update this privacy statement from time to time. We will notify you of any material changes by posting the updated policy on our website or through other communication channels.</p>
                        <p><b>If you have any questions or concerns about this privacy statement or how we use your activity data, please contact us at privacy@manishpacersarmy.com.</b></p>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-12">
                    <SidebarWidget />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import SidebarWidget from '../Common/SidebarWidget'
export default {
    name: 'PrivacyPolicy',
    components: {
        SidebarWidget
    }
}
</script>