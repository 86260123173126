<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <AboutUs />
        <Feedback />
        <OnlineCoaching />
        <CourseAdvisor  class="pb-100" />
        <Subscribe />
        <Partner class="ptb-70" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import AboutUs from '../AboutFive/AboutUs'
import Feedback from '../AboutFive/Feedback'
import OnlineCoaching from '../AboutFive/OnlineCoaching'
import CourseAdvisor from '../Common/CourseAdvisor'
import Subscribe from '../AboutFive/Subscribe'
import Partner from '../Common/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageFive',
    components: {
        Navbar,
        PageTitle,
        AboutUs,
        Feedback,
        OnlineCoaching,
        CourseAdvisor,
        Subscribe,
        Partner,
        Footer,
    },
}
</script>