import axios from 'axios'
import moment from 'moment'
import * as constant from '../config/constant.js'

export const gallerylist = async () => {
    var newss = []
    var token = localStorage.getItem('jwt')
    const res = await axios.get(`${constant.base_url}/gallery`, {
      headers: {
        token,
      },
    })
    console.log(res.data.data)
    var finalRes = res.data.data
    var count = 0
    finalRes.forEach((items) => {
      ++count
      var key=items.category//moment(items.date_time).isBefore(moment())?'expired':'upcoming';
      let obj = {
        id: items.id,
        src: constant.base_url_media+'/'+items.filepath, 
        thumb: constant.base_url_media+'/'+items.filepath, 
        title: items.title,
        key
      }
      newss.push(obj)
    })
    return newss
  }
