<template>
  <div class="apply-instructor-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="headtext">
                <p style="font-size: 27px; margin-bottom: 20px">Heads up!</p>
                <p>Welcome to Manish Pacer’s Army.Being a member of MPA, we would like to know about you.Take your time and complete this form. We keep your data
                  private.(Field marked * are mandatory).</p>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
          <div class="apply-instructor-content">
            <div class="tabs" id="tabsId">
              <b-tabs content-class="mt-3" v-model="tabIndex">
                <b-tab title="Basic Information" active>
                  <div class="register-form">
                    <div class="basicInfo">
                      <form>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label
                                >Full Name
                                <span class="redColor">*</span></label
                              >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                v-model="this.UserData.name"
                                @change="onChange()"
                              />
                              <div >
                                <label
                                >Date Of Birth
                                 <span class="redColor">*</span></label
                              >
                              <VueDatePicker v-model="this.UserData.dob" @change="onChange()" auto-apply format="MM/dd/yyyy"  placeholder="Date of birth"></VueDatePicker>
                              <label><span>Blood Group</span></label>
                              <b-form-select
                                v-model="this.UserData.blood_group"
                              >
                                <b-form-select-option value="O+"
                                   >	O+</b-form-select-option
                                >
                                <b-form-select-option value="O-"
                                  >O-</b-form-select-option
                                >
                                <b-form-select-option value="A+"
                                  >A+</b-form-select-option
                                >
                                <b-form-select-option value="A-"
                                  >A-</b-form-select-option
                                >
                                <b-form-select-option value="B+"
                                  >B+</b-form-select-option
                                >
                                <b-form-select-option value="B-"
                                  >B-</b-form-select-option
                                >
                                <b-form-select-option value="AB+"
                                  >AB+</b-form-select-option
                                >
                                <b-form-select-option value="AB-"
                                  >AB-</b-form-select-option
                                >
                              </b-form-select>
                              <label>T-Shirt Size</label>
                              <b-form-select
                                v-model="this.UserData.tshirt_size"
                              >
                                <b-form-select-option value="XS"
                                  >	XS</b-form-select-option
                                >
                                <b-form-select-option value="S"
                                  >	S</b-form-select-option
                                >
                                <b-form-select-option value="M"
                                  >	M</b-form-select-option
                                >
                                <b-form-select-option value="L"
                                  >	L</b-form-select-option
                                >
                                <b-form-select-option value="XL"
                                  >	XL</b-form-select-option
                                >
                                <b-form-select-option value="XXL"
                                  >	XXL</b-form-select-option
                                >
                              </b-form-select> 
                              <label>Sex<span class="redColor">*</span></label>
                              <div id="radioClass">
                                <b-form-group @change="onChange()" v-slot="{ sex }" >
                                  <b-form-radio v-model="this.UserData.gender" :aria-describedby="sex" name="some-radios" value="1">Male</b-form-radio>
                                  <b-form-radio v-model="this.UserData.gender" :aria-describedby="sex" name="some-radios" value="0">Female</b-form-radio>
                                </b-form-group>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <label>Running Photo</label>
                              <input type="file" accept="image/jpeg" @change=uploadImage class="form-control" >
                              <b-img left src="https://picsum.photos/125/125/?image=58" alt="Left image" 
                              style="margin-top: 16px; "></b-img>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Health & Fitness" >
                  <div class="register-form">
                    <div class="basicInfo">
                      <p>
                        MPA follow the running activity of every member. This
                        helps to give proper guidance. Provide access to the
                        Health and fitness data to assess, evaluate and suggest
                        your workouts plan.
                      </p>
                      <form>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <div class="row">
                                <!-- <label for="">Height </label> -->
                                <div class="col-lg-6 col-md-12">
                                    <label for="">Height (in Feet)</label>
                                    <input type="number" step="0.01" v-model="height" @change="lengthConv(height)" class="form-control" placeholder="Height (in Feet)" />
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                  <label for="">Height (cm)</label>
                                  <input type="number" class="form-control"
                                    placeholder="Height (cm)" step="0.01" v-model="height_cm" readonly/>
                              </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Weight (in Kg)</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Weight (in Kg)"
                                    style="margin-top:4px;"
                                    v-model="this.UserData.weight"
                                  />
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              <label>Could you please provide a brief summary of
                                your medical history, including any previous
                                illnesses or conditions you have experienced, if
                                applicable?&nbsp;(Write N/A if not applicable).</label>
                              <label style="font-weight: 400"
                                ></label
                              >
                              <textarea
                                name=""
                                class="form-control"
                                cols="10"
                                rows="2"
                                v-model="this.UserData.medical_history"
                              ></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              <label for="">What running gear do you currently use while
                                running? Please list all the running gears you
                                wear.&nbsp;(Write N/A if not applicable).</label>
                              <label style="font-weight: 400"
                                ></label
                              >
                              <textarea
                                name=""
                                class="form-control"
                                cols="10"
                                rows="2"
                                v-model="this.UserData.running_gear"
                              ></textarea>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Which smartwatch do you use while
                                running?</label>
                              <b-form-select
                                v-model="this.UserData.smart_watch"
                              >
                                <b-form-select-option value="Apple"
                                  >Apple</b-form-select-option
                                >
                                <b-form-select-option value="Garmin"
                                  >Garmin</b-form-select-option
                                >
                                <b-form-select-option value="Coros"
                                  >Coros</b-form-select-option
                                >
                                <b-form-select-option value="Fitbit"
                                  >Fitbit</b-form-select-option
                                >
                                <b-form-select-option value="Suunto"
                                  >Suunto</b-form-select-option
                                >
                                <b-form-select-option value="Polar"
                                  >Polar</b-form-select-option
                                >
                                <b-form-select-option value="No, I do not use any watch while running"
                                  >No, I do not use any watch while running</b-form-select-option
                                >
                              </b-form-select>
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Which running apps do you use to track your
                                workouts? </label>
                              <b-form-select
                                v-model="this.UserData.running_app"
                              >
                                <b-form-select-option value="Strava"
                                  >Strava</b-form-select-option
                                >
                                <b-form-select-option value="Garmin Connect"
                                  >Garmin Connect</b-form-select-option
                                >
                                <b-form-select-option value="Runkeeper"
                                  >Runkeeper </b-form-select-option
                                >
                                <b-form-select-option value="Nike Run Club"
                                  >Nike Run Club</b-form-select-option
                                >
                                <b-form-select-option value="MapMyRun"
                                  >MapMyRun</b-form-select-option
                                >
                                <b-form-select-option value="Runtastic"
                                  >Runtastic</b-form-select-option
                                >
                                <b-form-select-option value="Endomondo"
                                  >Endomondo</b-form-select-option
                                >
                                <b-form-select-option value="Pumatrac"
                                  >Pumatrac</b-form-select-option
                                >
                                <b-form-select-option value="No, I do not use any apps"
                                  >No, I do not use any apps</b-form-select-option
                                >
                              </b-form-select>                              
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                        </div>
                        <p></p>
                        <div class="row" >
                          <div class="col-lg-12 col-md-12" >
                            <b-form-checkbox
                            id="checkbox-1"
                            v-model="UserData.app_permission"
                            name="checkbox-1"
                            value="accepted"
                            :disabled="isCheckboxDisabled"
                            unchecked-value="not_accepted" 
                            style="margin-left: -20px;"  
                            >&nbsp;
                            I allow to give access to my running activity data through 
                            </b-form-checkbox>                              
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Training" >
                  <div class="register-form">
                    <div class="basicInfo">
                      <label
                        >We can serve you better if you provide your training goals. It will help us to uplift your fitness goal.</label
                      >
                      <form>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Select your training location</label>
                              <select
                                class="form-select form-control"
                                style="margin-top: 2px"
                                v-model="this.UserData.location"
                              >
                                <option value="Nehru Stadium">Nehru Stadium</option>
                                <option value="Atal Khel Parisar">Atal Khel Parisar</option>
                                <option value="Malhaar Ashram">Malhaar Ashram</option>
                                <option value="Online">Online</option>
                              </select>
                            </div>
                            <div class="col-lg-6 col-md-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">I am</label>
                              <select
                                class="form-select form-control"
                                style="margin-top: 2px; margin-bottom: 2px"
                                v-model="this.UserData.running_goal"
                              >
                                <option value="1">
                                  Training of my first marathon
                                </option>
                                <option value="2">
                                  Training of my half marathon
                                </option>
                                <option value="3">
                                  Looking to improve my marathon time
                                </option>
                                <option value="4">An advanced marathoner</option>
                                <option value="5">
                                  Getting ready for Ultra Runs
                                </option>
                                <option value="6">Training for a 5k race</option>
                                <option value="7">
                                  Training for a 10k race
                                </option>
                                <option value="7">A beginning runner</option>
                                <option value="9">
                                  Not sure what program is right for me
                                </option>
                                <option value="10">
                                  Looking for something else
                                </option>
                                <option value="11">
                                  I am looking for Comrades Marathon
                                </option>
                                <option value="12">
                                  I am looking for tracking
                                </option>
                              </select>
                            </div>
                            <div class="col-lg-6 col-md-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                             <label for=""> I wish to</label>
                            </div>
                          </div>
                          <div style="">
                            <div class="row">
                              <div class="col-lg-12 col-md-12">
                                 <div id="checkBoxClass">
                                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                              <b-form-checkbox-group
                                id="checkbox-group-1"
                                v-model="selected"
                                :options="iwishoptions"
                                :aria-describedby="ariaDescribedby"
                                name="flavour-1"
                              ></b-form-checkbox-group>
                            </b-form-group> 
                                 </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div></b-tab
                >
                  <b-tab title="Nutrition" 
                  ><div class="register-form">
                    <div class="basicInfo">
                      <form>
                        <div class="form-group"></div>
                        <p></p>
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">I am</label>
                              <select
                                class="form-select form-control"
                                style="margin-top: 2px; margin-bottom: 2px"
                                v-model="this.UserData.diet" >
                                <option value="1">
                                  Vegetarian
                                </option>
                                <option value="2">
                                  Non-vegetarian
                                </option>
                                <option value="3">
                                  Vegan
                                </option>
                                <option value="4">
                                  Pescatarian
                                </option>
                                <option value="5">
                                  Flexitarian
                                </option>
                                <option value="6">
                                  Ovo-vegetarian
                                </option>
                                <option value="7">
                                  Lacto-vegetarian
                                </option>
                                <option value="8">
                                  Paleo
                                </option>
                                <option value="9">
                                  Keto
                                </option>
                              </select>
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Do you prefer to take Nutrition guidance?</label>
                              <div id="radioClass3">
                                <div style="margin-top: 4px;;">
                                  <b-form-group label="" v-slot="{ nutrition_guidance}">
                                <b-form-radio  name="some-radios" v-model="this.UserData.nutrition_guidance" value="1">Yes</b-form-radio>
                                <b-form-radio name="some-radios" v-model="this.UserData.nutrition_guidance" value="0">No</b-form-radio>
                                </b-form-group>
                                </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                          </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              <label for="">I take these nutrition supplements</label>
                              <textarea
                                name=""
                                class="form-control"
                                cols="10"
                                rows="2"
                                v-model="this.UserData.nutrition_supplements"
                              ></textarea>
                            </div>
                          </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Social" 
                  ><div class="register-form">
                    <div class="basicInfo">
                      <form>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-lg-6 col-md-12"  id="comminicate">
                              Which communication method do you prefer?
                              <ul>
                                <li><b-form-checkbox
                                  id="checkbox-email"
                                  v-model="UserData.email_communicate"
                                  name="checkbox-email"
                                  value="accepted"
                                  unchecked-value="not_accepted"                            
                                  >
                                  Email
                                </b-form-checkbox> </li>
                                <li>
                                  <b-form-checkbox
                                  id="checkbox-sms"
                                  v-model="UserData.sms_communicate"
                                  name="checkbox-sms"
                                  value="accepted"
                                  unchecked-value="not_accepted"                            
                                  >
                                  SMS
                                </b-form-checkbox> 
                                </li>
                                <li>
                                  <b-form-checkbox
                                  id="checkbox-whatsapp"
                                  v-model="UserData.whatsapp_communicate"
                                  name="checkbox-whatsapp"
                                  value="accepted"
                                  unchecked-value="not_accepted"
                                  >
                                  Whatsapp
                                </b-form-checkbox> 
                                </li>
                              </ul>
                            </div>
                            <div class="col-lg-6 col-md-12"></div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12">
                              Do you wish to be connected to MPA through Social Media?
                              <div style="margin-top: 4px;">
                                <div id="radioClass2">
                                   <b-form-group label="" v-slot="{ mpa }">
                                <b-form-radio v-model="UserData.connect_mpa" :aria-describedby="mpa" name="some-radios" value="1">Yes</b-form-radio>
                                <b-form-radio v-model="UserData.connect_mpa" :aria-describedby="mpa" name="some-radios" value="0">No</b-form-radio>
                                </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                           <div class="col-lg-6 col-md-12"  id="comminicate">
                            Which social media do you use?
                             <ul>
                               <li>
                                <b-form-checkbox
                                  id="checkbox-twitter"
                                  v-model="UserData.twitter"
                                  name="checkbox-twitter"
                                  value="accepted"
                                  unchecked-value="not_accepted"                            
                                  >
                                  Twitter
                                </b-form-checkbox> </li>
                               <li>
                                <b-form-checkbox
                                  id="checkbox-facebook"
                                  v-model="UserData.facebook"
                                  name="checkbox-facebook"
                                  value="accepted"
                                  unchecked-value="not_accepted"                            
                                  >
                                  Facebook
                                </b-form-checkbox>
                               </li>
                               <li>
                                <b-form-checkbox
                                  id="checkbox-linkedin"
                                  v-model="UserData.linkedin"
                                  name="checkbox-linkedin"
                                  value="accepted"
                                  unchecked-value="not_accepted"                            
                                  >
                                  LinkedIn
                                </b-form-checkbox>
                               </li>
                             </ul>
                           </div>
                           <div class="col-lg-6 col-md-12"></div>
                         </div>
                        </div>                      
                      </form>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Payment" 
                  ><div class="register-form">
                    <div class="basicInfo">
                      <form>
                        <div class="form-group">
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label for="">Which payment plan do you prefer?</label>
                              <select
                                class="form-select form-control"
                                style="margin-top: 2px"
                                v-model="this.UserData.membership_plan"   
                                 @change="DisplayAmount(this.UserData.membership_plan)" >
                                <option v-for="item in planlist" :value="item.id">{{ item.plan_name }}</option>
                              </select>
                            </div>
                            <div class="col-lg-6 col-md-12">
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <label
                                >Amount
                               </label
                              >
                              <input
                                type="text"
                                v-model="this.amount"
                                class="form-control"
                                placeholder="" 
                                readonly/>                                
                            </div>
                            <div class="col-lg-6 col-md-12"></div>
                          </div>
                        </div>
                      </form>

                      <div>
                          <div v-html='ccaveForm' />                          
                        </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Thank You" 
                  ><div class="register-form">
                    <div class="basicInfo">
                      <form>
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <p>
                              Thank you for providing us all your information.
                              We wish you a happy running!
                            </p>                           
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <button
                    type="submit"
                    class="profileButton"
                    id="btn1"
                    style=""
                    @click="tabIndex--"
                    v-if="tabIndex > 0"
                  >
                    Back
                  </button>
                  &nbsp;
                  <b-alert style="width: 250px; float: left" fade :variant="alert_class_name" v-model="showAlert">
                        {{alertMessage}}
                    </b-alert>  
                  <!-- <button
                    type="submit"
                    class="profileButton"
                    id="btn2"
                    style=""
                    @click="updateProfile()"
                    v-if="tabIndex < 5"
                  >                  
                    Save
                  </button>&nbsp;
                  <button
                    type="submit"
                    class="profileButton"
                    id="btn2"
                    style=""
                    @click="pay()"
                    v-else-if="tabIndex < 6"
                  >                  
                    Pay Now
                  </button>&nbsp;
                  </button>&nbsp; -->
                  <button
                    type="submit"
                    id="btn3"
                    class="profileButton"
                    style=""
                    @click="nextClick()"
                    v-if="tabIndex < 5"    
                    :disabled='isDisabled'                
                  >
                    Next
                  </button>&nbsp;
                  <button
                  type="submit"
                  class="profileButton"
                  id="btn2"
                  style=""
                  @click="updateProfile()"
                  v-else-if="tabIndex < 6"
                >                  
                  Pay Now
                </button>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from './../../apis/authapi.js';
import * as dash_api from './../../apis/dashapi.js';
import * as auth from './../../helpers/authHelper.js';
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import router from "../../router";

//import ccavenue from "ccavenue";
export default {
  components: {
    VueDatePicker
  },
  data() {
    return {
      date:null,
      tabIndex: 0,
      userDetails: "",
      checked:'',
      alert_class_name: "",      
      showAlert: false,
      isdisabled:true,
      alertMessage: 'This is the alert message',
      selected: [], // Must be an array reference!
        iwishoptions: [
          { text: 'Participate in running event of marathon', value: '1' },
          { text: 'Be just fit and need not to participate much in marathon event', value: '2' },
          { text: 'Reduce my weight and need intensive training plans and guidance', value: '3' },
          { text: 'Run for fun and need not involve in intensive training plans', value: '4' },
          { text: 'Be an elite runner and look for intensive training and participate in marathon events', value:'5'},
          { text: 'Join online training and unable to come offline', value: '6' },
          { text: 'Be a member of MPA but need not want to pursue your training plan for now', value: '7' },
        ],
      nutrition_guidance:'',
      UserData:{
          user_uid:'',
          name:'',
          gender:'',
          profile_image:'',
          dob:'21/08/1988',
          tshirt_size:'M',
          blood_group:'O+',
          height:'',
          weight:'',
          shoe_size:'',
          medical_history:'',
          running_gear:'',
          smart_watch:'',
          running_app:'',
          app_permission:'not_accepted',
          running_goal:'',
          i_wish_to:'',
          email_communicate:'',
          sms_communicate:'',
          whatsapp_communicate:'',
          connect_mpa:'',
          twitter:'',
          facebook:'',
          linkedin:'',
          instagram:'',
          other_media:'',
          payment_method:'',
          membership_plan:'',
      },
      isCheckboxDisabled: false,
      amount :'',
      ccaveForm:'',
      height_in:'',
      height_cm:'',
      planlist:[],
    };
  },
  watch:{
    height_in(newValue) {
      console.log(newValue);
    },
  },
  mounted() {
    let Token_Name = localStorage.getItem("jwt");
    if (Token_Name != null) {
      this.userDetails = localStorage.getItem("userDetails");
      this.userDetails = JSON.parse(this.userDetails);
      this.isSession = true;
    }
    this.getUserDetail();
    this.getPlanList();
  },
  computed: {
  	isDisabled: function(){
      if(this.UserData.name==null || this.UserData.name==''){
        this.isdisabled = true;
      }else if(this.UserData.gender==null){
        this.isdisabled = true;
      }else if(this.UserData.dob=="Invalid date" || this.UserData.dob==""){
        this.isdisabled = true;
      } else{
        this.isdisabled = false;
      }
    	return this.isdisabled ;
    }
  },
  watch:{
    UserData: {
      handler: function (val, oldVal) {
        if (oldVal.running_app!='No, I do not use any apps')
         {
          // this.isCheckboxDisabled=true;
         } 
        else
          this.isCheckboxDisabled=false;
      },
      deep: true,
    },
    isCheckboxDisabled(newValue) {
      console.log(newValue);
    },
  },
  methods: {
    async pay(){      
      // var ss=  await api.ccavPayment(this.UserData,this.router);
      // this.ccaveForm = ss;
      // console.log(ss);   
      // setTimeout(() => {
      //   document.getElementById('checkout').submit();
      // }, 5000);
      
    },
    DisplayAmount(val)
    {
      this.planlist.map(x => {
        if(x.id == val)
        this.amount=x.plan_price
      });
      
    },
    onChange(){        
        if(this.UserData.name==null || this.UserData.name==''){                  
          this.delayedAlert();
          this.alertMessage = "Name is Required.";
          this.alert_class_name = "danger";   
          this.isdisabled = true;
        }else if(this.UserData.gender==null){                  
          this.delayedAlert();
          this.alertMessage = "Gender is Required.";
          this.alert_class_name = "danger";   
          this.isdisabled = true;
        }else if(this.UserData.dob=="Invalid date" || this.UserData.dob==""){                  
          this.delayedAlert();
          this.alertMessage = "Date of birth is Required.";
          this.alert_class_name = "danger";   
          this.isdisabled = true;
        } else{
          this.isdisabled = false;
        }        
     },
    uploadImage(e){
        const selectedImage = e.target.files[0];
        this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.UserData.profile_image = e.target.result;
            };
            reader.readAsDataURL(fileObject);
    },
    dateTime(datetime) {
        return moment(datetime).format("MM/DD/YYYY");
    },
    dateFormat(datetime) {
        return moment(datetime).format("YYYY-MM-DD");
    },
    async getUserDetail(){
      let Token_Name = localStorage.getItem("jwt");
      var data = {
        user_uid:this.userDetails.user_uid,        
      };
      var response =await api.userbyid(data, this.router);
      if(response.data.status==true && response.data.msg=="User Details Found" ){
        this.UserData.name = response.data.data.name;                
        this.UserData.gender = response.data.data.gender;
        this.UserData.dob = this.dateTime(response.data.data.dob) ;
        if(response.data.data.tshirt_size!=null ){
          this.UserData.tshirt_size = response.data.data.tshirt_size;
        }else{
          this.UserData.tshirt_size = 'M';
        }
        if(response.data.data.blood_group!=null){
          this.UserData.blood_group = response.data.data.blood_group;  
        }else{
          this.UserData.blood_group = 'O+';
        }
        this.UserData.blood_group = response.data.data.blood_group;
        this.UserData.height = response.data.data.height;
        this.UserData.height_inches = response.data.data.height_inches;
        this.UserData.weight = response.data.data.weight;
        this.UserData.shoe_size = response.data.data.shoe_size;
        this.UserData.medical_history = response.data.data.medical_history;
        this.UserData.running_gear = response.data.data.running_gear;
        this.UserData.smart_watch = response.data.data.smart_watch;
        this.UserData.running_app = response.data.data.running_app;
        this.UserData.app_permission = response.data.data.app_permission;
        this.UserData.running_goal = response.data.data.running_goal;
        this.UserData.i_wish_to = response.data.data.i_wish_to;
        this.UserData.email_communicate = response.data.data.email_communicate;
        this.UserData.sms_communicate = response.data.data.sms_communicate;
        this.UserData.whatsapp_communicate = response.data.data.whatsapp_communicate;
        this.UserData.connect_mpa = response.data.data.connect_mpa;
        this.UserData.twitter = response.data.data.twitter;
        this.UserData.facebook = response.data.data.facebook;
        this.UserData.linkedin = response.data.data.linkedin;
        this.UserData.instagram = response.data.data.instagram;
        this.UserData.other_media = response.data.data.other_media;
        this.UserData.payment_method = response.data.data.payment_method;
        this.UserData.membership_plan = response.data.data.membership_plan;     
        this.UserData.user_uid = response.data.data.user_uid;
        this.UserData.nutrition_supplements = response.data.data.nutrition_supplements;
        this.UserData.diet = response.data.data.diet;
        this.UserData.nutrition_guidance = response.data.data.nutrition_guidance;
        this.UserData.location = response.data.data.location;
        const codes = response.data.data.i_wish_to.split(/\s*,\s*/);
        this.selected.push(...codes);
        this.lengthGet()
        if(this.UserData.app_permission==1){
          this.UserData.app_permission = 'accepted';
        }
        this.onChange();
      }else{   
        if(!Token_Name){
          router.push({ name: 'AuthenticationPage' });
        } 
      }        
    },
    async updateProfile() {
      // console.log(this.planlist)
          let validation = 0
                if(this.UserData.name==null || this.UserData.name==''){                  
                  this.delayedAlert();
                  this.alertMessage = "Name is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                }else if(this.UserData.gender==null){                  
                  this.delayedAlert();
                  this.alertMessage = "Gender is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                }else if(this.UserData.dob=="Invalid date" || this.UserData.dob==""){                  
                  this.delayedAlert();
                  this.alertMessage = "Date of birth is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                } 
                if(validation==0){
                  this.UserData.dob = moment(this.UserData.dob).format("YYYY-MM-DD"); 
                  this.UserData.i_wish_to =  this.selected.join(', ');                    
                  // console.log('Update form submitted', this.selected);
                  var ss=  await api.updateProfile(this.UserData,this.router);
                  if(ss.statuscode==1){
                    // this.delayedAlert();
                    this.alertMessage = "Record Saved Successfully.";
                    this.alert_class_name = "success";   
                  }else{
                    // this.delayedAlert();
                    this.alertMessage = ss.message;
                    this.alert_class_name = "danger";   
                  }
                  // console.log(ss);

                }
                
    },
    async makePayment() {
      // console.log(this.planlist)
          let validation = 0
                if(this.UserData.name==null || this.UserData.name==''){                  
                  this.delayedAlert();
                  this.alertMessage = "Name is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                }else if(this.UserData.gender==null){                  
                  this.delayedAlert();
                  this.alertMessage = "Gender is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                }else if(this.UserData.dob=="Invalid date" || this.UserData.dob==""){                  
                  this.delayedAlert();
                  this.alertMessage = "Date of birth is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                }else if(this.UserData.location!=="" && this.UserData.membership_plan!=="" ){                  
                  this.delayedAlert();
                  this.alertMessage = "Date of birth is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                } 
                if(validation==0){
                  this.UserData.dob = moment(this.UserData.dob).format("YYYY-MM-DD"); 
                  this.UserData.i_wish_to =  this.selected.join(', ');                    
                  // console.log('Update form submitted', this.selected);
                  var ss=  await api.updateProfile(this.UserData,this.router);
                  if(ss.statuscode==1){
                    // this.delayedAlert();
                    this.alertMessage = "Record Saved Successfully.";
                    this.alert_class_name = "success";   
                  }else{
                    // this.delayedAlert();
                    this.alertMessage = ss.message;
                    this.alert_class_name = "danger";   
                  }
                  // console.log(ss);

                }
                
    },
    async getPlanList() {
          this.planlist = await dash_api.getPlanList();
          console.log(this.planlist)
    },
    async nextClick() {
          this.tabIndex++;
          await this.updateProfile();
    },
    async lengthConv(val) {
      this.UserData.height = parseInt(val)
      this.height = parseFloat(val).toFixed(2)
      var height_cm = parseInt(val) * 30.48;
      height_cm += (val - parseInt(val)) * 25.4;
      this.height_cm =height_cm
      this.UserData.height_inches =parseFloat((val - parseInt(val)) * 100).toFixed(2)
      console.log(height_cm)
    },
    async lengthGet() {
      var val = this.UserData.height +"."+ this.UserData.height_inches 
      val = parseFloat(val)
      this.height = val
      var height_cm = parseInt(val) * 30.48;
      height_cm += (val - parseInt(val)) * 25.4;
      this.height_cm =height_cm
      
    },
    delayedAlert() {     
          this.showAlert = true;
          setTimeout(() => {
              this.showAlert = false;
          }, 5000);
    },    
  }
}
</script>
<style>
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #606060 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border-color: #004aad #004aad #fff !important;
}
.nav {
  font-weight: 700 !important;
  color: #606060 !important;
  font-size: 16px !important ;
}
.nav-link {
  color: #606060 !important;
}
.nav-link :hover {
  font-weight: 700 !important;
  color: #606060 !important;
  font-size: 16px !important;
}
.nav-tabs .nav-link.hover {
  border-color: #004aad #004aad #fff !important;
}
.basicInfo {
  width: 100%;
  /* height: 680px; */
  padding-bottom: 12px;
}
.profileButton {
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: var(--fontSize);
}
.redColor {
  color: red;
}
.headtext {
  margin-left: 33px;
  font-weight: 500;
}
.headtext p {
  line-height: 22px;
}
.tab {
  overflow: hidden;
}
.tab-content {
  height: 100%;
}
#radioClass .form-check-label{
  margin-right: 12px;
  margin-left: 0px;
  margin-top: 0px;
}
#radioClass2 .form-check-label{
  margin-right: 9px;
  margin-left: 0px;
  margin-top: 0px;
}
#radioClass3 .form-check-label{
  margin-right: 12px;
  margin-left: 0px;
  margin-top: 0px;
}
#radioClass3 .form-check{
  display: inline-block;
  margin-left: 6px;
}
#radioClass .form-check{
display: inline-block;
margin-left: 6px;
}
#radioClass2 .form-check{
display: inline-block;
margin-left: 8px;
    margin-bottom: 3px;;
}
#btn1{
  width: 143px; float: left;margin-left: 22px;
}
#btn2{
  width: 143px; float: right;
}
#btn3{
  width: 143px; float: right;margin-right: 22px;
}
#labelFont span
{
  font-weight: 500;
}
.register-form form .form-group label{
  font-weight: 400 !important;
  margin-bottom: 7px !important;
}
.register-form{
  padding-top: 1px;
}
#checkBoxClass{
  width: 70%;
}
.register-form form button {
  background-color: transparent  !important;
  color: #606060 !important;
  margin-top:0px  !important;
  padding: 2.5px 30px !important;
}
.dp__button svg{
  display: none;
}
.loginFormDiv1 form {
  margin: 0px 4px;
}
@media only screen and (max-width: 767px) {
  .basicInfo{
    width: 100%;
  }
  .register-form{
    padding-top: 2px;
  }
  #checkBoxClass{
    width: 100%;
  }
  .headtext{
    text-indent: 0px;
  }
  .tab-content{
    padding-top: 0px;
  }
  #btn1 ,#btn2 ,#btn3{
    width: 100%;
  }
  #btn3{
    margin-top: 4px;
    margin-right: 0px;
  }
  #btn1{
    margin-bottom: -19px;
    margin-left: 0px;
  }
  .register-form form .form-group label{
    width: 100%;
  }
}
#comminicate ul li{
  list-style: none;
  display: inline-block;
}
#comminicate li{
  margin: 0px 30px;
}
#comminicate ul{
  padding-left: 0px;
}
#comminicate  .form-check{
  padding-left: 0px;;
}
/* #tabsId .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
   pointer-events: none;
} */
</style>
