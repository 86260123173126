<template>
    <div class="blog-area ptb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img1.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">Education</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    University Admissions Could Face Emergency Controls
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 30, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img2.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">Online</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    Online Learning Can Prepare Students For A Fast-Changing
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 29, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img3.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">Learning</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    As Learning Moves Online, Trigger Warnings Must Too
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 28, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img7.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">EDUCATION</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    4 Steps To Quality Training In Times Of Urgency
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 1, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img8.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">ONLINE</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    100 Blended Learning Resources For Teachers
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 2, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img9.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">Learning</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    20 Examples Of Project-Based Learning
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 3, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img10.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">EDUCATION</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    Instructional Design And Adult Learners
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 4, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image">
                            <router-link to="/single-blog-one" class="d-block">
                                <img src="../../assets/img/blog/img11.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <a href="#" class="category">ONLINE</a>
                            <h3>
                                <router-link to="/single-blog-one">
                                    Join ATD 2021 International Conference & EXPO
                                </router-link>
                            </h3>
                            <ul class="post-content-footer d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/img/user/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li><i class='flaticon-calendar'></i> April 5, 2022</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>