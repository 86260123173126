<template>
    <div>
        <Navbar />
        <MainBanner />
        <Featured />
        <WhyChooseUs />
        <!-- <TopSellingCourses /> -->
        <!-- <Funfacts /> -->
        <Feedback />
        <ExpertCoaches />
        <!-- <AppDownload /> -->
        <!-- <Blog /> -->
        <FitnessCoaching />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomePages/MainBanner'
import Featured from '../HomePages/Featured'
import WhyChooseUs from '../HomePages/WhyChooseUs'
// import TopSellingCourses from '../GymCoaching/TopSellingCourses'
// import Funfacts from '../GymCoaching/Funfacts'
import Feedback from '../HomePages/Feedback'
import ExpertCoaches from '../HomePages/ExpertCoaches'
// import AppDownload from '../GymCoaching/AppDownload'
// import Blog from '../GymCoaching/Blog'
import FitnessCoaching from '../HomePages/FitnessCoaching'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePages',
    components: {
        Navbar,
        MainBanner,
        Featured,
        WhyChooseUs,
        // TopSellingCourses,
        // Funfacts,
        Feedback,
        ExpertCoaches,
        // AppDownload,
        // Blog,
        FitnessCoaching,
        Footer,
    }
}
</script>