<template>
    
    <div class="courses-details-area ptb-100">
        <div class="container">
            <div class="courses-details-header">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="courses-title">
                            <p>Join us now! whether you’re just getting started on your running journey or you’re an experienced runner looking for that edge, our plan will work for you.</p>
                        </div>
                        
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <!-- <div class="courses-price">
                            <div class="courses-review">
                                <div class="review-stars">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <span class="reviews-total d-inline-block">(3 reviews)</span>
                            </div>
                            <div class="price"></div>
                            <a href="#" class="default-btn"><i class="flaticon-user"></i>Join Now<span></span></a>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-details-image-style-two text-center">
                        <img src="../../assets/img/courses/IMG-20220317-WA0003.jpg" alt="image" style="height: 470px;">
                    </div><br>
                    <div class="courses-meta">
                        <h5><strong>Whether you are a new runner or have many marathons under your belt. There is something for you!</strong></h5>
                        <br>
                            <ul>
                                <li>
                                    <img src="../../assets/img/courses/newRunner.png" alt="" width="50px" style="margin-left: 34px;">
                                    <p><b>New Runners</b></p>
                                    <p class="pText1">Who want to complete their first Marathon!</p>
                                </li>
                                <li>
                                    <img src="../../assets/img/courses/interM.png" alt="" width="50px"  style="margin-left: 34px;">
                                    <p><b>Intermediate Runners</b></p>
                                    <p class="pText1">Who want to take their running to the next level!</p>
                                   
                                </li>
                                <li>
                                    <img src="../../assets/img/courses/interR.png" alt="" width="50px"  style="margin-left: 34px;">
                                    <p><b>Advanced Runners</b></p>
                                    <p class="pText1">Who want to win their age group or place top consistently!</p>
                                   
                                </li>
                            </ul>
                        </div>
                    <div class="courses-details-desc-style-two">
                        <h3>Description</h3>
                        <p><strong>Hi! Welcome to Manish Pacer’s Army for running, the only community you need to get fit and believe in yourself “MY RUN MY PACE”.</strong></p>
                        <p>The Weekly Plan we write for you are also customized to your age, goals, training experience and injury history.</p>
                       <p>You don’t have to be an elite runner, in your 20’s, or even run fast to fit in with this group.</p>
                       <p>The Manish Pacer’s Army is filled with all types of runners – young, old, marathoners, new to the sport, grizzled veterans and more</p>
                       <p>What we do all have in common is a love for running and a desire to improve, stay healthy and enjoy the sport more!</p> 
                       <p>So, I can almost guarantee you’ll fit in with this group and love your new team!</p>
                       <!-- <h3>Courses Video</h3>
                        <div class="courses-curriculum">
                            <h3>Python Introduction</h3>
                            <ul>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Python Introduction</span>
                                    <div class="courses-meta">
                                        <span class="questions">5 questions</span>
                                        <span class="duration">01 Hour</span>
                                        <span class="status">Preview</span>
                                    </div>
                                </a></li>
                            </ul>
                            <h3>Stepping into the World of Python</h3>
                            <ul>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">NumPy Introduction</span>
                                    <div class="courses-meta">
                                        <span class="duration">15 Min</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">NumPy Getting Started</span>
                                    <div class="courses-meta">
                                        <span class="duration">30 Min</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">NumPy Creating Arrays</span>
                                    <div class="courses-meta">
                                        <span class="duration">45 Min</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">NumPy Array Indexing</span>
                                    <div class="courses-meta">
                                        <span class="questions">4 questions</span>
                                        <span class="duration">1 Hour</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">NumPy Array Slicing</span>
                                    <div class="courses-meta">
                                        <span class="duration">1.5 Hour</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                            </ul>
                            <h3>Python MySQL</h3>
                            <ul>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Python MySQL</span>
                                    <div class="courses-meta">
                                        <span class="duration">01 Hour</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Python MySQL Create Database</span>
                                    <div class="courses-meta">
                                        <span class="questions">3 questions</span>
                                        <span class="duration">1.1 Hour</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                                <li><a href="#" class="d-flex justify-content-between align-items-center">
                                    <span class="courses-name">Python MySQL Create Table</span>
                                    <div class="courses-meta">
                                        <span class="duration">1.5 Hour</span>
                                        <span class="status locked"><i class="flaticon-password"></i></span>
                                    </div>
                                </a></li>
                            </ul>
                        </div>
                        <h3>What you'll learn</h3>
                        <div class="why-you-learn">
                            <ul>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Become an expert in Statistics
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Boost your resume with skills
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Gather, organize, data
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Use data for improved
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Present information KPIs
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Perform quantitative
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Analyze current data
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Discover how to find trends
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Understand the fundamentals
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class='flaticon-tick'></i>
                                        Use SQL to create, design
                                    </span>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <h3>Requirements</h3>
                        <ul class="requirements-list">
                            <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</li>
                            <li>The standard Lorem Ipsum passage, used since the 1500s.</li>
                        </ul>
                        <h3>Who this course is for:</h3>
                        <ul class="audience-list">
                            <li>Beginners to programming and data science</li>
                            <li>Students eager to learn about job opportunities in the field of data science</li>
                            <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                            <li>People interested in a Business Intelligence Analyst career</li>
                        </ul>
                        <h3>Meet Your Instructors</h3>
                        <div class="courses-author">
                            <div class="author-profile-header"></div>
                            <div class="author-profile">
                                <div class="author-profile-title">
                                    <img src="../../assets/img/user/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                    <div class="author-profile-title-details">
                                        <div class="author-profile-details">
                                            <h4>James Anderson</h4>
                                            <span class="d-block">Photographer, Author, Teacher</span>
                                        </div>
                                    </div>
                                </div>
                                <p>James Anderson is a celebrated photographer, author, and teacher who brings passion to everything he does.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            </div>
                        </div>
                        <div class="courses-reviews">
                            <h3>Course Rating</h3>
                            <div class="rating">
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star checked"></span>
                                <span class="bx bxs-star"></span>
                            </div>
                            <div class="rating-count">
                                <span>4.1 average based on 4 reviews.</span>
                            </div>
                            <div class="row">
                                <div class="side">
                                    <div>5 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-5"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>02</div>
                                </div>
                                <div class="side">
                                    <div>4 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-4"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>03</div>
                                </div>
                                <div class="side">
                                    <div>3 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-3"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>04</div>
                                </div>
                                <div class="side">
                                    <div>2 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-2"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>05</div>
                                </div>
                                <div class="side">
                                    <div>1 star</div>
                                </div>
                                <div class="middle">
                                    <div class="bar-container">
                                        <div class="bar-1"></div>
                                    </div>
                                </div>
                                <div class="side right">
                                    <div>00</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="courses-review-comments">
                            <h3>3 Reviews</h3>
                            <div class="user-review">
                                <img src="../../assets/img/user/u1.png" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                    </div>
                                    <span class="d-inline-block">Ravi Shrivastava</span>
                                    <p>Marathon runner</p>
                                </div>
                                <!-- <span class="d-block sub-comment">Excellent</span> -->
                                <p>From the moment I joined MPA, I felt welcomed by a group of passionate and supportive individuals who share a common love for running. The members range from beginners to experienced runners, creating an inclusive environment where everyone feels encouraged and motivated. The camaraderie among club members is truly inspiring, and it's incredible to witness everyone cheering each other on during races and training sessions.</p>
                                <p>Coach of MPA Ath. Manish Gaud's well-structured training programs have been instrumental in helping me improve my running abilities. The coach Mr. Ath. Both coach of MPA Ath. Manish Gaud and Mr. Bharat Bairagi are knowledgeable, experienced, and dedicated to assisting each member in reaching their goals. They provide personalized training schedule, advice and guidance, ensuring that everyone receives the attention they need.</p>
                                
                            </div>
                            <div class="user-review">
                                <img src="../../assets/img/user/u2.png" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <span class="d-inline-block">Parag Choudhary</span>
                                    <p>Executive Director, Ruchi Infrastructures Ltd</p>
                                    
                                </div>
                                <!-- <span class="d-block sub-comment">Video Quality!</span> -->
                                <p>It is necessary we need direction and magnitude to finish line for any training program, for which one need plan to get to the starting line feeling strong, healthy and moreover confident. The best marathon training program is one that works for each individual.
For all the above one need “GURU” and I personally feel the best Guru for me is Mr Manish Gaur Sir and Mr Bharat Bairagi Sir from MANISH PACERS ACADEMY located at Indore (M.P).
MPA have various plan for individuals ranging from Beginners/Starter training program, Intermediate Runner Training program and Advance Marathon or professional runner training program.

</p>

                            </div>
                            <div class="user-review">
                                <img src="../../assets/img/user/u3.png" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                    </div>
                                    <span class="d-inline-block">Jaishree Tapadia</span>
                                    <p>Prof & head of Physiology, SAIMS Indore</p>
                                </div>
                                <!-- <span class="d-block sub-comment">Perfect Coding!</span> -->
                                <p>Manish Sir is a mentor that every person should have. I never thought I could run a km, let alone complete half marathons. But Manish Sir’s training is magic which can make anyone do it. He not only teaches you the right way to run but also helps you to develop endurance and strength.  </p>
                                <p>The best part about Manish Sir is how he provided his students with individual motivation and training. I remember once I had a knee injury so severe right before a hill marathon ( Sathara Run). Still, the way Manish sir pushed me to work harder, train and build myself slowly for this run and constantly motivated me, which made me ace this race is the best example of his brilliance as a teacher. I highly recommend him.</p>
                            </div>
                            <!-- <div class="user-review">
                                <img src="../../assets/img/user/user4.jpg" alt="image">
                                <div class="review-rating">
                                    <div class="review-stars">
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star checked'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <span class="d-inline-block">King Kong</span>
                                </div>
                                <span class="d-block sub-comment">Perfect Video!</span>
                                <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                            </div> -->
                        </div> 
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-sidebar-sticky">
                        <div class="courses-sidebar-information">
                            <p><strong>WE COVER IN THE TRAINING :</strong></p>
                            <ul class="info">
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>What to eat before & after a long run? </span>
                                       
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Training on a busy schedule </span>
                                       
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Psychological tricks to keep running</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i> How to prevent plantar fasciitis?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>How to run in the heat without dehydration?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>How to deal with Runner's knee?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>The secrets of core training</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i> Busting through weight loss plateaus</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i> How to run faster?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                   
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>What to look for in buying a shoe?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>When to push through pain? </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i> Clearing the mind of the negative</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i> Effective cross-training exercises</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>How to perfect your running form?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>How to fuel during the marathon?</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Effective recovery from injury</span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Foods that boost performance </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>What kind of yoga is best for runners?  </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Strategies to prevent bonking </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Relaxed running and breathing </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>How to deal with muscle soreness?  </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Running through sickness </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Defensive running tactics  </span>
                                        
                                    </div>
                                </li>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><i class="fa fa-circle" style="font-size:10px"></i>Understanding the vitamins and supplements every runner needs, and many more.</span>
                                        
                                    </div>
                                </li>
                               

                            </ul>
                            <!-- <div class="btn-box">
                                <a href="#" class="default-btn"><i class="flaticon-shopping-cart"></i>Add to Cart<span></span></a>
                            </div> -->
                            <!-- <div class="courses-share">
                                <div class="share-info">
                                    <span>Share This Course <i class="flaticon-share"></i></span>
                                    <ul class="social-link">
                                        <li><a href="https://www.facebook.com/manish.goud.54?mibextid=LQQJ4d" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                        <li><a href="https://www.instagram.com/manish_pacers_army/?utm_source=q" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
export default {
    name: 'CoursesDetails'
}
</script>
<style>
.courses-meta ul li
{
    display: table-cell;
    width: 330px;
    margin: 3px;
}
.ptext1{
    width: 220px;
}

@media only screen and (max-width: 767px) {
    .courses-meta ul li
{
    display: inline-block;
    width: 100%;
    margin: 0 auto;
    padding: 2px;
}
.ptext1{
    width: 100% !important;
}
}

.courses-meta ul {
    padding-left: 1px;
}
.courses-sidebar-information .info li
{
    padding-bottom: 0px !important;
    line-height: 1.1 !important;
    font-size: 15px !important;
}

</style>