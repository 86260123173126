<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="contact-info">
                        <span class="sub-title">Contact Details</span>
                        <h2>Get in Touch</h2>
                        <p>We'd love to hear from you! If you have any questions, feedback, or inquiries, please don't hesitate to reach out to us. Our dedicated team is here to assist you and provide any information you need.</p>
                        <ul>
                            <li>
                                <div class="icon">
                                    <a class="icon" href="https://goo.gl/maps/wmw2LiEUhFQqN3hg9"><i class='bx bx-map'></i></a>
                                </div>
                                <h3>Our Training Ground</h3>
                                <p><b>Atal Khel Parisar</b></p>
                                <p>236, Sector B, Slice 1, Aranya Nagar, Vijay Nagar, Indore, Madhya Pradesh 452010</p>
                                
                            </li>
                            <li>
                                <div class="icon">
                                    <a class="icon" href="https://goo.gl/maps/H3WLC7G7ha18KkBVA"><i class='bx bx-map'></i></a>
                                </div>
                                
                                <p><b>Nehru International Cricket Stadium</b></p>
                                <p>40, Big Nehru Stadium, Agra Bombay Road, Sanyogita Ganj, Indore - 452001</p>
                                
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                </div>
                                <h3>Contact</h3>
                                <p>Mobile: +91-78792-39123 </p>
                                <p>Mail: manishpacersarmy@gmail.com</p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-time-five'></i>
                                </div>
                                <h3>Hours of Training</h3>
                                <p>Monday - Saturday: Morning 6:00 am to 8:00 am</p>
                                <p>Sunday : 5:00 am to 8:00 am</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h2>Ready to Get Started?</h2>
                        <p>Your email address will not be published. Required fields are marked *</p>
                        <form id="contactForm" @submit.prevent>
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <input type="text" name="name" id="name" placeholder="Your name">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <input type="email" name="email" id="email" placeholder="Your email address">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="phone_number" id="phone_number" placeholder="Your phone number">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea name="message" id="message" cols="30" rows="5" placeholder="Write your message..."></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Contact',
    methods: {
        formSubmit() {
            console.log('form submitted');
        },
    },
}
</script>