<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Products" />
        <Products />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Products from '../ShopOne/Products'
import Footer from '../Layouts/Footer'

export default {
    name: 'ShopPageOne',
    components: {
        Navbar,
        PageTitle,
        Products,
        Footer,
    },
}
</script>