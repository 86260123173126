<template>
    <div class="gym-feedback-area ptb-100">
        <div class="container-fluid">
            <div class="gym-feedback-slides">
                <carousel
                    :autoplay="50000"
                    :wrap-around="true"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="gym-feedback-box">
                            <p>{{slide.description}}</p>
                            <div class="title">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <Navigation />
                    </template> 
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Feedback',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                description: "From the moment I joined MPA, I felt welcomed by a group of passionate and supportive individuals who share a common love for running. The members range from beginners to experienced runners, creating an inclusive environment where everyone feels encouraged and motivated. The camaraderie among club members is truly inspiring, and it's incredible to witness everyone cheering each other on during races and training sessions",
                name: 'Ravi Shrivastava',
                position: 'Marathon Runner',
            },
            {
                id: 2,
                description: 'It is necessary we need direction and magnitude to finish line for any training program, for which one need plan to get to the starting line feeling strong, healthy and moreover confident. The best marathon training program is one that works for each individual.For all the above one need “GURU” and I personally feel the best Guru for me is Mr Manish Goud Sir and Mr Bharat Bairagi Sir from MANISH PACERS ACADEMY.',
                name: 'Parag Choudhary',
                position: 'Executive Director,Ruchi Infrastructures Ltd',
            },
            {
                id: 3,
                description: 'Manish Sir is a mentor that every person should have. I never thought I could run a km, let alone complete half marathons. But Manish Sir’s training is magic which can make anyone do it. He not only teaches you the right way to run but also helps you to develop endurance and strength.',
                name: 'Jaishree Tapadia',
                position: 'Prof & head of Physiology, SAIMS Indore',
            },
           
        ],
    }),
})
</script>