import axios from 'axios'
import moment from 'moment'
import { useRoute } from 'vue-router'
import router from "../router";
import * as constant from '../config/constant.js'
export const register = async (name, email, password) => {
  const res=await axios.post(`${constant.base_url}/register`, {
        name,
        email,
        password,
      })
      .then((response) => {
        // // console.log(response)
        // if (response.data.status == true) {
        //   //alert(response.data.msg)
        //   // router.push({ name: 'AuthenticationPage' })
        // } else {
          return response;
          // alert(response.data.msg)
        // }
      })
      return res;
  }
  
  export const lostpassword = async (email) => {    
    const res=await axios.post(`${constant.base_url}/lostpassword`, {      
      email,      
    })
       .then((response) => {
         if (response.data.status == true) {          
             var  res = response.data;             
             return res;
         } else {            
         var  res = response.data;
         //alert('User Address or pin is not correct!')       
         return res;       
         }
         
       })     
       return res;  
   }

export const resetpassword = async (userid, password) => {
  axios
    .post(`${constant.base_url}/resetpassword`, {
      userid,      
      password,
    })
    .then((response) => {
      // console.log(response)
      if (response.data.status == true) {
        alert(response.data.msg)
        router.push({ name: 'AuthenticationPage' })
      } else {
        alert(response.data.msg)
      }
    })
    .catch((e) => {
      //console.log(e)
      return false
    })
}
export const login = async (username, password) => {      
  const res=await axios.post(`${constant.base_url}/login`,{username,
  password},)
     .then((response) => {
       if (response.data.status == true) {          
           var  res = response.data;
           var token = response.data.jsonWebToken
          var userDetails = response.data.data
          localStorage.setItem('jwt', token)
          localStorage.setItem('userDetails', JSON.stringify(userDetails))
          return res;
       } else {            
       var  res = response.data;
       //alert('User Address or pin is not correct!')       
       return res;       
       }
       
     })     
     return res;  
 }

  export const logout = async (router) => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('userDetails')    
    router.push({ name: 'AuthenticationPage' })
  }

  export const userbyid = async (req,res) => {        
    const response = await axios.get(`${constant.base_url}/getUserDetail/`+req.user_uid, {
      headers: {        
      },
    })    
    return response;
  } 

  export const updateProfile = async (data) => {
    const res=await axios.post(`${constant.base_url}/updateProfile`, {
        data
      })
      .then((response) => {
        console.log(response)
        if (response.data.statuscode == 1) {
          //alert(response.data.message)
          return response.data;
          //router.push({ name: 'AuthenticationPage' })
        } else {
          //alert(response.data.message)
          return response.data;
        }
      })
      return res; 
  }

  export const googleLogin = async (name, email, password) => {
    const res=await axios.post(`${constant.base_url}/googleLogin`, {
        name,
        email,
        password,
      })
      .then((response) => {
        
        if (response.data.status == true || response.data.status == 201) {          
          var  res = response.data;
          var token = response.data.jsonWebToken
          var userDetails = response.data.data
          localStorage.setItem('jwt', token)
          localStorage.setItem('userDetails', JSON.stringify(userDetails))
          return res;
        } else {            
        var  res = response.data;
        //alert('User Address or pin is not correct!')       
        return res.data;       
        }
        
      })
      return res;       
  }

  export const facebookLogin = async (user_uid) => {
    
    const res=await axios.post(`${constant.base_url}/facebookLogin`, {
      user_uid,
      })
      .then((response) => {
        
        if (response.data.status == true || response.data.status == 201) {          
          var  res = response.data;
          var token = response.data.jsonWebToken
          var userDetails = response.data.data
          localStorage.setItem('jwt', token)
          localStorage.setItem('userDetails', JSON.stringify(userDetails))
          return res;
        } else {            
        var  res = response.data;
        //alert('User Address or pin is not correct!')       
        return res.data;       
        }
        
      })
      return res;       
  }

  export const emailVerified = async (userid) => {
    const res=await axios.post(`${constant.base_url}/emailVerified`, {
        userid,             
      })
      .then((response) => {
        // console.log(response)
        if (response.data.status == true) {
          //alert(response.data.msg)
          //router.push({ name: 'AuthenticationPage' })
          return response;
        } else {
          return response;
        }
      })
      return res;
  }

  export const ccavPayment = async (data) => {
    const res=await axios.post(`${constant.base_url}/ccavPayment`, {
      data
    })
    .then((response) => {
       console.log(response)
      if (response.data.statuscode == 1) {
        //alert(response.data.message)
        return response.data;
        //router.push({ name: 'AuthenticationPage' })
      } else {
        //alert(response.data.message)
        return response.data;
      }
    })
    return res; 
  }
