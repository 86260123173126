<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Event Register" />
        <EventRegister />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import EventRegister from '../EventRegister/EventRegister'
import Footer from '../Layouts/Footer'

export default {
    name: 'EventRegisterPage',
    components: {
        Navbar,
        PageTitle,
        EventRegister,
        Footer,
    },
}
</script>