<template>
    <div class="trainer-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/m1.png" alt="image" style="width:460px;;height: 500px;">
                        <span class="designation">MANISH GOUD</span>
                        
                        <div class="content">
                            <h3>MANISH GOUD</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://www.facebook.com/manish.goud.54?mibextid=LQQJ4d" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li> -->
                                <li>
                                    <a href="https://instagram.com/manish.goud13?igshid=NTc4MTIwNjQ2YQ==" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://youtube.com/@Athmanisgoud13" class="d-block" target="_blank">
                                        <i class='bx bxl-youtube'></i>
                                    </a>
                                </li> 
                            </ul>
                        </div>
                       
                        
                    </div>
                   <div style="text-align: center;">
                    <p>
                        AFI Pre-level 1 Certified Coach
                        <br>Certified Dietician
                        <br>Certified Nutritionist
                        <br>Batchelor of Physical Education (B.PE)
                        <br>Inspirational and Motivational Coach for Running
                     </p>
                   </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/image1.png" alt="image" style="width:500px;height: 500px;">
                        <span class="designation">BHARAT BAIRAGI</span>
                        <div class="content">
                            <h3>BHARAT BAIRAGI</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://www.facebook.com/manish.goud.54?mibextid=LQQJ4d" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li> -->
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                 <li>
                                    <a href="https://youtube.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-youtube'></i>
                                    </a>
                                </li> 
                            </ul>
                        </div>
                        
                    </div>
                    <div style="text-align: center;">
                    <p>
                        AFI Pre-level 1 Certified Coach
                        <br>Seven Wonders Marathon (Virtual)
                        <br>Procam finisher
                      
                     </p>
                   </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Trainer'
}
</script>