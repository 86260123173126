<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img3.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img4.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <!-- <span class="sub-title">Distance learning</span> -->
                        <h2>The Power of Guidance: Maximizing Performance with a Dedicated Marathon Trainer</h2>
                        <p>Manish Pacer’s Army holds the key to unlocking the full potential of a runner's performance. With our guidance, runners can achieve remarkable results in their marathon preparation. These trainers possess the expertise to create personalized training plans tailored to individual strengths and weaknesses. </p>
                        <p>We provide valuable insights on proper running techniques, endurance building, nutrition, and injury prevention. By analysing progress and making necessary adjustments, a MPA ensures that each runner reaches their peak physical condition on race day. </p>
                        <p>Our knowledge, motivation, and support form the foundation for maximizing performance and achieving extraordinary results in the challenging world of marathon running.</p>
                        <ul class="features-list">
                            <li><span><i class="fa fa-circle" style="font-size:10px"></i>	Full Marathon</span></li>
                            <li><span><i class="fa fa-circle" style="font-size:10px"></i>	Half Marathon</span></li>
                            <li><span><i class="fa fa-circle" style="font-size:10px"></i>	Ultra Run</span></li>
                            <li><span><i class="fa fa-circle" style="font-size:10px"></i>Comrades Marathon</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1">
            <img src="../../assets/img/shape/shape1.png" alt="image">
        </div>
        <div class="shape2">
            <img src="../../assets/img/shape/shape2.png" alt="image">
        </div>
        <div class="shape3">
            <img src="../../assets/img/shape/shape3.png" alt="image">
        </div>
        <div class="shape4">
            <img src="../../assets/img/shape/shape4.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'StartingNewCareer'
}
</script>