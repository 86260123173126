<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <BuildSkillsOnline />
        <Funfacts />
        <Partner class="ptb-70" />
        <Feedback />
        <MeetOurInstructors />
        <PotentialUnlimitedAccess />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import BuildSkillsOnline from '../AboutFour/BuildSkillsOnline'
import Funfacts from '../AboutFour/Funfacts'
import Partner from '../Common/Partner'
import Feedback from '../AboutFour/Feedback'
import MeetOurInstructors from '../Common/MeetOurInstructors'
import PotentialUnlimitedAccess from '../Common/PotentialUnlimitedAccess'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageFour',
    components: {
        Navbar,
        PageTitle,
        BuildSkillsOnline,
        Funfacts,
        Partner,
        Feedback,
        MeetOurInstructors,
        PotentialUnlimitedAccess,
        Footer,
    },
}
</script>