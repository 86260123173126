<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <ProjectManagementSkills />
        <Funfacts />
        <Testimonials />
        <CourseAdvisor class="ptb-100" />
        <Partner class="ptb-70" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import ProjectManagementSkills from '../AboutTwo/ProjectManagementSkills'
import Funfacts from '../AboutTwo/Funfacts'
import Testimonials from '../AboutTwo/Testimonials'
import CourseAdvisor from '../Common/CourseAdvisor'
import Partner from '../Common/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageTwo',
    components: {
        Navbar,
        PageTitle,
        ProjectManagementSkills,
        Funfacts,
        Testimonials,
        CourseAdvisor,
        Partner,
        Footer,
    },
}
</script>