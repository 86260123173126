<template>
    <div class="events-area pt-100 pb-70">
        <div class="container">
            
            <div class="shorting">
                <div class="row">
                    <div 
                        class="col-lg-4 col-sm-6 col-md-6"
                        v-for="item in itemFilter"
                        :key="item.id"
                    >
                        <div class="single-events-box">
                            <div class="image">
                                <!-- <router-link to="/single-events" class="d-block"> -->
                                    <img :src="item.image" alt="image" style="width:380px;height: 380px;" id="zoomA">
                                <!-- </router-link> -->
                                <span class="date">{{item.date}}</span>
                            </div>
                            <div class="content">
                                <h3 style="color:#004aad"> {{item.title}}
                                    
                                </h3>
                                <span class="location">
                                    <i class="bx bx-map"></i>
                                    {{item.location}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from './../../apis/newsapi';
export default {
    name: 'NewsMedia',
    data: function (){
        return {
            itemFilterKey: 'all',
            items: [],
        }
    },
    beforeMount: async function () {
        this.items=await api.newslist();
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        all(){
            return this.items
        },
        happening(){
            return this.items.filter((item) => item.key == 'happening')
        },
        upcoming(){
            return this.items.filter((item) => item.key == 'upcoming')
        },
        expired(){
            return this.items.filter((item) => item.key == 'expired')
        },
    }
}
</script>
<style>
#zoomA {
  /* (A) OPTIONAL DIMENSIONS */
  width: 100%;
  height: auto;
 
  /* (B) ANIMATE ZOOM */
  /* ease | ease-in | ease-out | linear */
  transition: transform ease-in-out 0.3s;
}
 
/* (C) ZOOM ON HOVER */
#zoomA:hover { transform: scale(1.2); }
</style>