import axios from 'axios'
import moment from 'moment'
import * as constant from '../config/constant.js'

export const newslist = async () => {
    var newss = []
    var token = localStorage.getItem('jwt')
    const res = await axios.get(`${constant.base_url}/news`, {
      headers: {
        token,
      },
    })
    console.log(res.data.data)
    var finalRes = res.data.data
    var count = 0
    finalRes.forEach((items) => {
      ++count
      var date = items.post_date ? moment(items.post_date).format('ddd, DD MMM, YYYY'):''
      var key=items.category//moment(items.date_time).isBefore(moment())?'expired':'upcoming';
      let obj = {
        id: items.id,
        image: constant.base_url_media+'/'+items.filepath,
        date,
        title: items.heading,
        location: items.category,
        key,
      }
      newss.push(obj)
    })
    return newss
  }

