<template>
    <div class="online-platform-area pt-100" >
        <div class="container">
            <div class="row align-items-center" >
                <div class="col-lg-6 col-md-12">
                    <div class="online-platform-image">
                        <img src="../../assets/img/gym/123.png" alt="image" >
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="online-platform-content">
                        <span class="sub-title">Start Your Running Presence!</span>
                        <h2>JOIN THE #1 CLUB AND TRAINING FOR MARATHON RUNNERS</h2>
                        <p>The Club exists to provide education, support, and guidance to runners who love to challenge themselves and see the transformation running can bring.</p>
                        <router-link to="/join" class="default-btn">
                            <i class="flaticon-user"></i>
                            Get Started 
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape23"><img src="../../assets/img/shape/shape23.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'FitnessCoaching'
}
</script>