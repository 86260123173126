<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Welcome to Manish Pacer's Army" /> -->
        <UserRegister />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import UserRegister from '../UserRegister/UserRegister'
import Footer from '../Layouts/Footer'

export default {
    name: 'UserRegisterPage',
    components: {
        Navbar,
        // PageTitle,
        UserRegister,
        Footer,
    },
}
</script>