<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <StartingNewCareer />
        <DistanceLearning />
        <OnlineCoaching class="pt-0" />
        <CourseAdvisor class="ptb-100" />
        <PotentialUnlimitedAccess />
        <!-- <Partner class="ptb-70" /> -->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import StartingNewCareer from '../AboutOne/StartingNewCareer'
import DistanceLearning from '../AboutOne/DistanceLearning'
import OnlineCoaching from '../AboutOne/OnlineCoaching'
import CourseAdvisor from '../Common/CourseAdvisor'
import PotentialUnlimitedAccess from '../AboutOne/PotentialUnlimitedAccess'
import Partner from '../Common/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        Navbar,
        PageTitle,
        StartingNewCareer,
        DistanceLearning,
        OnlineCoaching,
        CourseAdvisor,
        PotentialUnlimitedAccess,
        Partner,
        Footer,
    },
}
</script>