<template>
  <div class="profile-authentication-area ptb-40">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="login-form">
            <div id="login" class="LoginForm">
              <h2 style="text-align: center">Login</h2>
              <div class="row">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <div class="googleLoginDiv" style="">
                    <div id="googleLogin" ref="googleLoginBtn" />
                  </div>
                </div>
              </div>
              <p></p>
              <!-- <div class="row">      
                <div class="col-lg-12 col-md-6 col-sm-6">          
                   <scope v-bind="$props" v-model="model" v-slot="slotProps">
                      <Button v-bind="{ ...buttonProps, ...$attrs }" :scope="slotProps">
                        <template
                          v-for="(slot, slotName) of $slots"
                          v-slot:[slotName]="slotProps"
                        >
                          <slot v-bind="slotProps" :name="slotName" />
                        </template>
                      </Button>
                    </scope>
                </div>    
              </div> -->
              <div class="row">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <div class="facebookDiv" style="">
                        <scope v-bind="$props" v-model="model" v-slot="slotProps">
                          <Button v-bind="{ ...buttonProps, ...$attrs }" :scope="slotProps">
                            <template
                              v-for="(slot, slotName) of $slots"
                              v-slot:[slotName]="slotProps"
                            >
                              <slot v-bind="slotProps" :name="slotName" />
                            </template>
                          </Button>
                        </scope>
                        <!-- <b-button  variant="outline-primary" style="width:400px;margin-bottom: 9px;;">
                            <i class="fa fa-facebook" aria-hidden="true" ></i>&nbsp;
                                CONTINUE WITH FACEBOOK
                        </b-button> -->
                  </div>
                </div>
              </div>

              
              <form @submit.prevent style="">
                <h6 class="hClass" ><span>OR</span></h6>
                <div class="form-group">
                  <label>Username or email</label>
                  <input
                    v-model="username"
                    type="text"
                    class="form-control"
                    placeholder="Username or email"
                  />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                  />
                </div>
                
                <div class="row align-items-center" style="margin-bottom: 8px;">
                  <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                    <p style="font-size: 14px">
                      <input type="checkbox" id="test2" />
                      <label for="test2">Remember me</label>
                    </p>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap"
                  >
                    <a
                      @click="showLostPassword()"
                      style="cursor: pointer; font-size: 13px"
                      class="lost-your-password"
                      >Forgot your password?</a
                    >
                  </div>
                </div>
              
                
                <div class="row align-items-center">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <vue-recaptcha
                      v-show="showRecaptcha"
                      sitekey="6LfryVomAAAAAKS-OHMh8lUPL4CGSlMBXpCWQp6n"
                      size="normal"
                      theme="light"
                      @verify="recaptchaVerified"
                      @expire="recaptchaExpired"
                      @fail="recaptchaFailed"
                      ref="vueRecaptcha"
                    >
                    </vue-recaptcha>
                  </div>
                </div>
                <button type="submit" @click="formSubmit()">Log In</button>
                <p style="display: flex;padding: 13px 4px;">
                  Don't have an account ? &nbsp;<router-link to="/signup"
                    >Sign Up</router-link
                  >
                </p>
              </form>
              <div class="col-lg-12 col-md-6 col-sm-6">
                <b-alert fade :variant="alert_class_name" v-model="showAlert">
                  {{ alertMessage }}
                </b-alert>
              </div>
            </div>
            <div id="lostPassword" class="login-form">
              <div class="loginF">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    v-model="emailLostPassword"
                    type="email"
                    class="form-control"
                    placeholder="Enter Your Email"
                  />
                </div>
                <button class="resetBtn" @click="lostpassword()">
                  Reset Password
                </button>
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <p style="display: flex;margin-top: 7px;;">
                    Don't have an account ? &nbsp;<router-link
                      to="/signup"
                      >Sign Up</router-link
                    >
                  </p>
                  <b-alert fade :variant="alert_class_name" v-model="showAlert">
                    {{ alertMessage }}
                  </b-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import vueRecaptcha from "vue3-recaptcha2";
import * as api from "./../../apis/authapi.js";
import * as auth from "./../../helpers/authHelper.js";
import jwtDecode from "jwt-decode";
import router from "../../router";
import VFacebookLogin from "vue-facebook-login-component-next";
import Scope from "../Authentication/facebook/Scope";
import Button from "../Authentication/facebook/Button.vue";
import { getComponentProps } from "../Authentication/facebook/utils";
export default defineComponent({
  name: "Authentication",
  components: {
    vueRecaptcha,
    VFacebookLogin,
    Scope,
    Button,
  },
  props: { ...Scope.props, ...Button.props },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    buttonProps() {
      return getComponentProps(Button, this.$props);
    },
  },
  data() {
    const router = useRouter();
    const username = "";
    const password = "";
    const usernameNew = "";
    const passwordNew = "";
    const emailNew = "";
    const emailLostPassword = "";
    const sessionStorage = "";
    return {
      router,
      username,
      password,
      usernameNew,
      passwordNew,
      sessionStorage: window.sessionStorage,
      emailNew,
      isRecaptchaVerified: false,
      isRecaptchaExpired: false,
      isRecaptchaFailed: true,
      showRecaptcha: true,
      emailLostPassword,
      alert_class_name: "",
      showAlert: false,
      alertMessage: "This is the alert message",
      isLogin: false,
      googleEmail: "",
      googleUser: "",
    };
  },
  async mounted() {
    // const fbvalue = JSON.parse(this.sessionStorage.getItem('fbssls_592735172848676'));
    // const signedRequest = jwtDecode(fbvalue.authResponse.signedRequest);
    //const code = jwtDecode(signedRequest.code);
    // console.log("Session-------- ",fbvalue.authResponse);
    // console.log("SignedRequest------",signedRequest);
    //console.log("SignedRequest Code------",code);
    // if (fbvalue.authResponse.accessToken) {
    //    const resultLogin = await FacebookLogin.getProfile({ fields: ['email'] });
    //    console.log('Facebook users email is', resultLogin.email);
    //    }

    auth.isLoggedOut();
    $("#lostPassword").hide();
    const gClientId =
      "719040921734-n8bu0cf428c9mgf61s6e60mhq1p92di1.apps.googleusercontent.com";
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
      auto_select: true,
    });
    window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
      text: "continue_with", // or 'signup_with' | 'continue_with' | 'signin'
      size: "large", // or 'small' | 'medium'
      width: "320", // max width 400
      theme: "filled_blue", // or 'outline' // or 'filled_black' |  'filled_blue'
      logo_alignment: "left", // or 'center'
    });
  },
  watch: {
    username(newValue) {
      console.log(newValue);
    },
    password(newValue) {
      console.log(newValue);
    },
    usernameNew(newValue) {
      console.log(newValue);
    },
    passwordNew(newValue) {
      console.log(newValue);
    },
    emailNew(newValue) {
      console.log(newValue);
    },
  },
  methods: {
    handleLogin(response) {
      console.log("User Successfully Logged In", response);
    },
    handleLoginFailed() {
      console.log("User Cancelled or Abort the Login");
    },
    async handleCredentialResponse(response) {
      console.log("Google Login--", response);
      const USER_CREDENTIAL = jwtDecode(response.credential);
      console.log(USER_CREDENTIAL);
      this.googleEmail = USER_CREDENTIAL.email;
      this.googleUser = USER_CREDENTIAL.given_name;
      // Put your backend code in here
      this.googleReg();
    },
    showLostPassword() {
      $("#login").hide();
      $("#lostPassword").show();
    },
    delayedAlert() {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
    async formSubmit() {
      console.log("form submitted", this.isCaptcha());
      if (this.isCaptcha()) {
        var response = await api.login(this.username, this.password);
        if (response.status == true) {
          console.log(response.msg);
          this.delayedAlert();
          this.alertMessage = response.msg;
          this.alert_class_name = "success";
          router.push({ name: "UserDashboardPage" });
        } else {
          console.log(response.msg);
          this.delayedAlert();
          this.alertMessage = response.msg;
          this.alert_class_name = "danger";
        }
      } else {
        this.delayedAlert();
        this.alertMessage = "Please verify captcha.";
        this.alert_class_name = "danger";
      }
    },
    formSubmitReg() {
      console.log("register form submitted");
      var ss = api.register(
        this.usernameNew,
        this.emailNew,
        this.passwordNew,
        this.router
      );
      console.log(ss);
    },
    async lostpassword() {
      console.log("Lost Password form submitted");
      var response = await api.lostpassword(
        this.emailLostPassword,
        this.router
      );
      if (response.status == true) {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "success";
      } else {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "danger";
      }
    },
    recaptchaVerified(response) {
      this.isRecaptchaVerified = true;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = false;
      console.log(response);
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = true;
      this.isRecaptchaFailed = false;
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaFailed() {
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = true;
      console.log("response");
      console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    isCaptcha() {
      if (
        this.isRecaptchaVerified &&
        !this.isRecaptchaExpired &&
        !this.isRecaptchaFailed
      ) {
        return true;
      } else {
        return false;
      }
    },
    async googleReg() {
      console.log("Google register submitted");
      var response = await api.googleLogin(
        this.googleUser,
        this.googleEmail,
        "mpa" + this.googleUser,
        this.router
      );
      if (response.status == true || response.status == 201) {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "success";
        router.push({ name: "UserDashboardPage" });
      } else {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "danger";
      }
    },
  },
});
</script>

<style>
.LoginForm {
  margin: 0 auto;
  width: 42%;
  /* padding: 10px;; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
 
 }
.LoginForm form {
  margin: 0px 48px;
}
.LoginForm form .form-group{
  margin-bottom: 12px !important;
}
.loginF
{
  padding: 34px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 7px;
}
 #lostPassword{
  margin: 0 auto;
  width: 50%;
}
@media only screen and (max-width: 767px) {
.LoginForm{
  box-shadow: none;
}
.LoginForm form {
  margin: 0px 4px;
}
.loginF
{
  padding: 4px;
    box-shadow: none;
    margin-top: 7px;
}

  .v-facebook-login{
    width: 100% !important;
    margin-left: 0 !important;
  }
  #lostPassword {
    width: 100%;
  }
  .LoginForm {
    width: 100%;
  }
  #googleLogin {
    width: 20px;
  }
  .googleLoginDiv {
    margin: 0 auto;
    width: 100%;
  }
  #googleLoginBtn {
    width: 250px;
  }
  .facebookDiv{
    margin: 0 auto;
    width:100%;
  }
 
}
.resetBtn {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
  font-size: var(--fontSize);
}

.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}

.hClass span {
  background: #fff;
  padding: 0 10px;
}

.googleLoginDiv {
  margin: 0 auto;
  display: block;
  width: 320px;
}
.facebookDiv{
  margin:0 auto;display:block;width:320px
}
.login-form form .lost-your-password-wrap a
{
  text-decoration: none;
}
.login-form{
  margin-bottom: 2px;;
}
</style>