<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Checkout" />
        <Checkout />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Checkout from '../Checkout/Checkout'
import Footer from '../Layouts/Footer'

export default {
    name: 'CheckoutPage',
    components: {
        Navbar,
        PageTitle,
        Checkout,
        Footer,
    },
}
</script>