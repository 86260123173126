<template>
  <div class="profile-authentication-area ptb-40">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="register-form1">
            <div class="loginFormDiv">
              <h2 style="text-align: center;font-weight:800;">Sign Up</h2>
              <div class="row">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <div class="googleLoginDiv" style="">
                    <div id="googleLogin" ref="googleLoginBtn" />
                  </div>
                </div>
              </div>
              <p></p>
              <div class="row">
                <div class="col-lg-12 col-md-6 col-sm-6">
                   <div class="facebookDiv" style="">
                    <scope v-bind="$props" v-model="model" v-slot="slotProps">
                      <Button
                        v-bind="{ ...buttonProps, ...$attrs }"
                        :scope="slotProps"
                      >
                        <template
                          v-for="(slot, slotName) of $slots"
                          v-slot:[slotName]="slotProps"
                        >
                          <slot v-bind="slotProps" :name="slotName" />
                        </template>
                      </Button>
                    </scope>
                    <!-- <b-button  variant="outline-primary" style="width:400px;margin-bottom: 9px;;">
                            <i class="fa fa-facebook" aria-hidden="true" ></i>&nbsp;
                                CONTINUE WITH FACEBOOK
                        </b-button> -->
                  </div>
                </div>
              </div>
             
              <form @submit.prevent style="">
                   <h6 class="hClass"><span>OR</span></h6>
                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    v-model="usernameNew"
                    class="form-control"
                    placeholder="Username"
                  />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    v-model="emailNew"
                    required
                    @change="validateEmail"
                    class="form-control"
                    placeholder="email"
                  />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    v-model="passwordNew"
                    class="form-control"
                    placeholder="Password"
                  />
                </div>
                <!-- <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p> -->
                <vue-recaptcha
                  v-show="showRecaptcha"
                  sitekey="6LfryVomAAAAAKS-OHMh8lUPL4CGSlMBXpCWQp6n"
                  size="normal"
                  theme="light"
                  @verify="recaptchaVerified"
                  @expire="recaptchaExpired"
                  @fail="recaptchaFailed"
                  ref="vueRecaptcha"
                >
                </vue-recaptcha><br>
                <button
                  type="submit"
                  @click="formSubmitReg()"
                  class="profileButton"
                >
                  Sign Up
                </button>
              </form>
              <p style="display: flex; margin:8px 45px !important">
                Already have an account ? &nbsp;<router-link to="/login"
                  >Sign In </router-link
                >
              </p>
              <b-alert fade :variant="alert_class_name" v-model="showAlert">
                {{ alertMessage }}
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, resolveTransitionHooks } from "vue";
import { useRouter } from "vue-router";
import vueRecaptcha from "vue3-recaptcha2";
import * as api from "./../../apis/authapi.js";
import * as auth from "./../../helpers/authHelper.js";
import jwtDecode from "jwt-decode";
import router from "../../router";
import VFacebookLogin from "vue-facebook-login-component-next";
import Scope from "../Authentication/facebook/Scope";
import Button from "../Authentication/facebook/Button.vue";
import { getComponentProps } from "../Authentication/facebook/utils";

export default defineComponent({
  name: "Registration",
  components: {
    vueRecaptcha,
    VFacebookLogin,
    Scope,
    Button,
  },
  props: { ...Scope.props, ...Button.props },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    buttonProps() {
      return getComponentProps(Button, this.$props);
    },
  },
  data() {
    const router = useRouter();
    const username = "";
    const password = "";
    const usernameNew = "";
    const passwordNew = "";
    const emailNew = "";
    return {
      router,
      username,
      password,
      usernameNew,
      passwordNew,
      emailNew,
      email: "",
      alert_class_name: "",
      showAlert: false,
      alertMessage: "This is the alert message",
      isRecaptchaVerified: false,
      isRecaptchaExpired: false,
      isRecaptchaFailed: true,
      showRecaptcha: true,
      startTime: {
        time: "",
      },
      endtime: {
        time: "",
      },
    };
  },
  mounted() {
    auth.isLoggedOut();
    const gClientId =
      "719040921734-n8bu0cf428c9mgf61s6e60mhq1p92di1.apps.googleusercontent.com";
    window.google.accounts.id.initialize({
      client_id: gClientId,
      callback: this.handleCredentialResponse,
      auto_select: true,
    });
    window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
      text: "signup_with", // or 'signup_with' | 'continue_with' | 'signin'
      size: "large", // or 'small' | 'medium'
      width: "320", // max width 400
      theme: "filled_blue", // or 'outline' // or 'filled_black' |  'filled_blue'
      logo_alignment: "left", // or 'center'
    });
  },
  watch: {
    username(newValue) {
      console.log(newValue);
    },
    password(newValue) {
      console.log(newValue);
    },
    usernameNew(newValue) {
      console.log(newValue);
    },
    passwordNew(newValue) {
      console.log(newValue);
    },
    emailNew(newValue) {
      console.log(newValue);
    },
  },
  methods: {
    validateEmail() {
      console.log("Email Validatioion");
      var regex =  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (regex.test(this.emailNew)) {
        this.email = this.emailNew;
      } else {
        this.email = "";
        this.delayedAlert();
        this.alertMessage = "Please enter a valid email address";
        this.alert_class_name = "danger";
      }
    },
    async handleCredentialResponse(response) {
      console.log("Google Login--", response);
      const USER_CREDENTIAL = jwtDecode(response.credential);
      console.log(USER_CREDENTIAL);
      this.googleEmail = USER_CREDENTIAL.email;
      this.googleUser = USER_CREDENTIAL.given_name;
      // Put your backend code in here
      this.googleReg();
    },
    async googleReg() {
      console.log("Google register submitted");
      var response = await api.googleLogin(
        this.googleUser,
        this.googleEmail,
        "mpa" + this.googleUser,
        this.router
      );
      if (response.status == true || response.status == 201) {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "success";
        router.push({ name: "UserDashboardPage" });
      } else {
        console.log(response.msg);
        this.delayedAlert();
        this.alertMessage = response.msg;
        this.alert_class_name = "danger";
      }
    },
    // formSubmit() {
    //   // console.log("form submitted");
    //   var ss = api.login(this.username, this.password);
    //   // console.log(ss);
    // },
    delayedAlert() {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
    async formSubmitReg() {
      if (this.isCaptcha()) {
        if (
          this.usernameNew != "" &&
          this.email != "" &&
          this.passwordNew != ""
        ) {
          console.log(
            "register form submitted",
            this.usernameNew + "---" + this.emailNew + "---" + this.passwordNew
          );
          var ss = await api.register(
            this.usernameNew,
            this.emailNew,
            this.passwordNew,
            this.router
          );
          console.log(ss);
          if (ss?.data?.status == true) {
            this.delayedAlert();
            this.alertMessage = ss.data.msg;
            this.alert_class_name = "success";
            this.router.push({ name: 'AuthenticationPage' } , () => {
              this.$toasted.show('Successfully Added User');
            })
          } else {
            this.delayedAlert();
            this.alertMessage = ss?.data?.msg;
            this.alert_class_name = "danger";
          }
        } else {
          this.delayedAlert();
          if (this.email == "") {
            this.alertMessage = "Please enter a valid email address.";
          } else {
            this.alertMessage = "All fields are required.";
          }

          this.alert_class_name = "danger";
        }
      } else {
        this.delayedAlert();
        this.alertMessage = "Please verify captcha.";
        this.alert_class_name = "danger";
        //alert("please verify captcha.")
      }
    },
    recaptchaVerified() {
      this.isRecaptchaVerified = true;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = false;
      // console.log(response);
      // console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      // console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      // console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = true;
      this.isRecaptchaFailed = false;
      // console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      // console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      // console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    recaptchaFailed() {
      this.isRecaptchaVerified = false;
      this.isRecaptchaExpired = false;
      this.isRecaptchaFailed = true;
      // console.log("response");
      // console.log("this.isRecaptchaVerified", this.isRecaptchaVerified);
      // console.log("this.isRecaptchaExpired", this.isRecaptchaExpired);
      // console.log("this.isRecaptchaFailed", this.isRecaptchaFailed);
    },
    isCaptcha() {
      if (
        this.isRecaptchaVerified &&
        !this.isRecaptchaExpired &&
        !this.isRecaptchaFailed
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
});
</script>
<style>

.loginFormDiv{
    margin:0 auto;width: 37% !important;
    padding: 10px;;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.loginFormDiv form {
  margin: 0px 48px;
}
.register-form1 label {
    padding-bottom: 1px;
}
.register-form1 .form-group {
    padding-bottom: 0px;
}
.register-form1{
    margin-top: 10px;
    margin-bottom: 20px;
}


@media only screen and (max-width: 767px){
    .loginFormDiv form {
  margin: 0px 4px;
}
.loginF{
width: 100%;
}
.loginFormDiv{
width: 100% !important;
box-shadow: none;;
}
.googleLoginDiv {
  margin: 0 auto;
  display: block;
  width: 320px;
}
.facebookDiv{
  margin:0 auto;display:block;width:320px
}

}
</style>