<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="FAQ" />
        <Faq />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Faq from '../FAQ/Faq'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'FaqPage',
    components: {
        Navbar,
        PageTitle,
        Faq,
        Subscribe,
        Footer,
    },
}
</script>