<template>
    <div class="gym-home-area">
        <div class="gym-banner-slides">
            <carousel
                :autoplay="15000"
                :wrap-around="true"
            >
                <Slide v-for="slide in carouselItems" :key="slide.id">
                    <div class="gym-banner-item">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="gym-banner-content">
                                        <h1>{{slide.heading}}</h1>
                                        <p>{{slide.description}}</p>
                                        <router-link to="/join" class="default-btn">
                                            <i class="flaticon-user"></i>
                                            Join Us
                                            <span></span>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="gym-banner-image">
                                        <img :src="slide.image" alt="image" style="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slide>
                
                <template #addons>
                    <Navigation />
                </template> 
            </carousel>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                heading: 'START YOUR RUNNING JOURNEY WITH EXPERT',
                description: 'Meet our experts, who have been training athletes for a decade now, expert in his field for training athletes track events to ultra marathon',
                image: require('../../assets/img/gym/DSC_0192-removebg.png'),
            },
            {
                id: 2,
                heading: "MANISH PACER'S ARMY IS MORE THAN A PLACE",
                description: 'Embrace the Challenge, Ignite your Inner Fire.\nRun Beyond Limits, Achieve your Heart\'s Desire with Manish Pacer’s Army !',
                image: require('../../assets/img/gym/b13.png'),
            },
            {
                id: 3,
                heading: 'WE PRIORITIZE YOUR HEALTH AND FITNESS',
                description: 'Dedicated to putting your health and fitness first, empowering you to thrive.',
                image: require('../../assets/img/gym/ban12.png'),
            },
        ],
    }),
})
</script>