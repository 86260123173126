<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion-tab">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <i :class="tab.icon"></i>
                            {{tab.name}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <accordion>
                                <accordion-item
                                    v-for="accordion in tab.accordions"
                                    :key="accordion.id"
                                >
                                    <template v-slot:accordion-trigger>
                                        <button class="accordion-button">
                                            <i :class='accordion.icon'></i>
                                            {{accordion.title}}
                                        </button>
                                    </template>
                                    <template v-slot:accordion-content>
                                        <p>{{accordion.shortDesc}}</p>
                                    </template>
                                </accordion-item>
                            </accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    },
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-flag',
                    name: 'Getting Started',
                    accordions: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'How can I join the running club?',
                            shortDesc: 'Manish Pacer’s Army (MPA) runs in the early morning hours. Visit us during the training hours or contact us through or email us. We also provide online training programs.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'What are the membership requirement or criteria?',
                            shortDesc: 'Anyone can become the member of MPA. We welcome all males and females, young and old, new to the sports. We ensure that you are not having any ailments or disease due to which you are unable to follow the fitness guidelines',
                            
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Is there an age limit or any specific fitness level required to join?',
                            shortDesc: 'There is no age limit for anyone to join us. We do utmost care to uplift the fitness level.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'What are the benefits of joining the running club?',
                            shortDesc: 'There are many benefits when you join our running club. Running is a sport that changes your life style. It not only increases your fitness but it also affects your routine and prepare you mind and soul to be positive in life.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'What types of running activities or programs does the club offer?',
                            shortDesc: 'Running activity includes Jogging, sprint, Long-distance run, trail run, Interval training, cross-country running, Hill run etc. It is always suggested to follow a training schedule to do different running activities.',
                        },
                        {
                            id: 6,
                            icon: 'bx bx-chevron-down',
                            title: 'What is the schedule for club runs and training sessions?',
                            shortDesc: 'Our regular time of training is morning 6:00 am to 8:00 am daily except on Sunday where this time starts a little earlier from 5:30 am to 9:00 am. We provide weekly schedule to every member.',
                        },
                    ]
                },
                {
                    id: 2,
                    icon: 'bx bxs-badge-dollar',
                    name: 'Pricing and Plans',
                    accordions: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Can I join Manish Pacer’s Army for free?',
                            shortDesc: 'No, this is not for free. You have to make a payment for joining us. We have both monthly and annual payment plans.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'What are the mode of payments after joining the Manish Pacer’s Army(MPA)?',
                            shortDesc: 'We accept UPI payment through Paytm / Google Pay. You can pay us in cash too.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Is payment refundable, if I wish to quit?',
                            shortDesc: 'You can opt for a monthly plan instead of annual. The payment is non-refundable and non-transferable.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Can I pay for annual membership, does it bring any benefit to the member?',
                            shortDesc: 'Yes, you can pay annual membership and it offers a discount in your monthly fees.',
                        },
                        // {
                        //     id: 5,
                        //     icon: 'bx bx-chevron-down',
                        //     title: 'Am I eligible for admission?',
                        //     shortDesc: 'Every school and program has different admission requirements for accepting students. These requirements can often be found on the program profiles. If you’d like more information or if the information is missing, submit a “Request Information” form to contact the institute directly.',
                        // },
                    ]
                },
                {
                    id: 3,
                    icon: 'bx bx-run',
                    name: 'Running Guide',
                    accordions: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: "What is Runner's Knee injury?",
                            shortDesc: "Runner's Knee is a common complaint among long-distance runners. Soreness is around and sometimes behind the kneecap. It's usually aggravated by running or climbing stairs. Knee may feel stiff and sore after sitting down for long periods. You might even hear a clicking sound when you bend or extend your knee. Runner's knee is usually caused by weakness in the middle quadriceps muscles (Vastus Medialis) and tight hamstrings or IT bands. You may also be at risk if you overpronate (your feet roll inward) when you run.First, you can reduce the pain and inflammation by icing your knees immediately after running. Work on strengthening your quad muscles, which will help support and stabilize your kneecap. Stretching hamstrings and IT bands will also help. Make sure you have the right kind of running shoes for your foot type. Also, make sure you're not running in worn-out shoes. Other factors that add to prevention are performing all required stretches after a run, strengthening of the glutes, strengthening of the hips, 'Ironing out' your running muscles with a foam roller after a run. If you are prone to runner's knee, ensure that you run in the middle of 'crowned' roads (roads that slope up towards the center) whenever possible.",
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'How do I use a Foam Roller?',
                            shortDesc: "After rigorous running workout like long runs or a speed run we find runners having tight quads, hamstring, calves, IT bands etc and this tightness usually does not completely release even after stretching. If runners continue to run with these tight muscles it leads to injuries. Using a foam roller with individual's own body weight, one can perform self-massage on the tight muscles. Benefits of a foam roller usage is similar to deep- tissue massage with benefits such as stretching muscles, decreasing muscle tension, releasing trigger points and increasing blood flow.",
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'What, in general, will most help me in overcoming my injuries?',
                            shortDesc: "Icing is an effective technique for quick recovery from soreness and faster rehabilitation after injury. In general for body soreness, we would recommend a nice ice bath! Load a huge drum or a bathtub with lots of ice and cold water. Get into it neck deep for 10-12 minutes and then rinse with warm water. You could add Epsom salt to the cold bath for a refreshing experience (It also helps rehydrate and recover fast).If you are icing a specific muscle or area for an injury or severe pain, the PRICE technique is very effective.'PRICE' treatment: P - Protection, R - Restrict activity, I- apply Ice, C- apply Compression, E- Elevate the injured area.The PRICE principle limits the amount of swelling at the injury and improves the healing process. Activity restriction for 48 to 72 hours will allow the healing process to begin. Ice should be applied for 15 to 20 mins every 60 to 90 mins. Elevating the limb will also keep the swelling to a minimum.Most of running injuries are also due to muscle weakness /imbalance and strengthening these muscles will help mitigate injuries in the long term",
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'What are the muscles groups that should be strengthened to overcome muscle weakness/ imbalances in runners?',
                            shortDesc: 'Muscle weakness /imbalance in runners can be overcome by doing upper and lower-extremity and core strength training as a routine. Runners should perform strength training.',
                        },
                        // {
                        //     id: 5,
                        //     icon: 'bx bx-chevron-down',
                        //     title: 'Am I eligible for admission?',
                        //     shortDesc: 'Every school and program has different admission requirements for accepting students. These requirements can often be found on the program profiles. If you’d like more information or if the information is missing, submit a “Request Information” form to contact the institute directly.',
                        // },
                    ]
                },
                // {
                //     id: 4,
                //     icon: 'bx bx-book-open',
                //     name: 'Usage Guides',
                //     accordions: [
                //         {
                //             id: 1,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How can I contact a school directly?',
                //             shortDesc: 'You can contact a school by filling out a “Contact Us” form. This form can be found to the right of both the institute and education program profiles and also at the bottom of these profiles.',
                //         },
                //         {
                //             id: 2,
                //             icon: 'bx bx-chevron-down',
                //             title: 'Where should I study abroad?',
                //             shortDesc: 'Only you can answer this question. However, we have some excellent resources to help you make the best decision. Take our "Where Should I Study Abroad?" quiz or read our country guides for an in-depth look at studying in specific countries',
                //         },
                //         {
                //             id: 3,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How do I find a study abroad program on vdemy.com?',
                //             shortDesc: 'You can find programs through our Find Education search tool. You can select the category you’d like to study, such as hospitality or business; the place where you’d like to study, whether a certain country or city; and/or the type of program, such as Bachelor or Master.',
                //         },
                //         {
                //             id: 4,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How do I find a school where I want to study?',
                //             shortDesc: 'Locating schools to study is exactly the same as finding an education program (see above question). Once the search results appear, click on the "Institutes" tab to view the institutes, or use our Find Schools search tool.',
                //         },
                //         {
                //             id: 5,
                //             icon: 'bx bx-chevron-down',
                //             title: 'Am I eligible for admission?',
                //             shortDesc: 'Every school and program has different admission requirements for accepting students. These requirements can often be found on the program profiles. If you’d like more information or if the information is missing, submit a “Request Information” form to contact the institute directly.',
                //         },
                //     ]
                // },
                // {
                //     id: 5,
                //     icon: 'bx bx-info-circle',
                //     name: 'General Guide',
                //     accordions: [
                //         {
                //             id: 1,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How can I contact a school directly?',
                //             shortDesc: 'You can contact a school by filling out a “Contact Us” form. This form can be found to the right of both the institute and education program profiles and also at the bottom of these profiles.',
                //         },
                //         {
                //             id: 2,
                //             icon: 'bx bx-chevron-down',
                //             title: 'Where should I study abroad?',
                //             shortDesc: 'Only you can answer this question. However, we have some excellent resources to help you make the best decision. Take our "Where Should I Study Abroad?" quiz or read our country guides for an in-depth look at studying in specific countries',
                //         },
                //         {
                //             id: 3,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How do I find a study abroad program on vdemy.com?',
                //             shortDesc: 'You can find programs through our Find Education search tool. You can select the category you’d like to study, such as hospitality or business; the place where you’d like to study, whether a certain country or city; and/or the type of program, such as Bachelor or Master.',
                //         },
                //         {
                //             id: 4,
                //             icon: 'bx bx-chevron-down',
                //             title: 'How do I find a school where I want to study?',
                //             shortDesc: 'Locating schools to study is exactly the same as finding an education program (see above question). Once the search results appear, click on the "Institutes" tab to view the institutes, or use our Find Schools search tool.',
                //         },
                //         {
                //             id: 5,
                //             icon: 'bx bx-chevron-down',
                //             title: 'Am I eligible for admission?',
                //             shortDesc: 'Every school and program has different admission requirements for accepting students. These requirements can often be found on the program profiles. If you’d like more information or if the information is missing, submit a “Request Information” form to contact the institute directly.',
                //         },
                //     ]
                // },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>