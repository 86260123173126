<template>
    <div class="profile-area pt-1 pb-1">
        <div class="container">
            <div class="profile-box">
                <div class="row align-items-center">
                  <div class="image">                            
                            <img :src="this.UserData.profile_image" alt="image" style="width: 200px;margin: 0 auto;display: block;">                            
                        </div>
                    <!-- <div class="col-lg-4 col-md-4">
                        <div class="image">                            
                            <img v-if="this.UserData.profile_image!=null" :src="this.UserData.profile_image" alt="image" style="width: 300px;">

                            <img v-else src="../../assets/img/user/images.png" alt="image" style="width: 300px;">
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12">
                        <div class="content">
                            <div class="register-form" style="margin-top: 0px;;">
                            <div class="basicInfo">
                            
                            <div class="form-group" style="margin: 5px;padding: 20px;;border-radius: 5px;;">
                            <div class="row">
                            <div class="col-lg-12 col-md-12">
                            <label>User Name</label>
                            <input type="text"  class="form-control" placeholder="UserName"
                                v-model="this.UserData.username"
                            >
                            </div>
                           
                            </div>
                            <p></p>
                            <div class="row">
                            <div class="col-lg-12 col-md-12">
                            <label>Email</label>
                            <input type="email" readonly  class="form-control" placeholder="Email"
                            v-model="this.UserData.email"
                            >
                            </div>
                           
                            </div>
                        <p></p>
                            <div class="row">
                            <div class="col-lg-12 col-md-12">
                            <label>Mobile Number</label>
                            <input type="text"  class="form-control" placeholder="Mobile Number"
                            v-model="this.UserData.phone_number"
                            >
                            </div>
                           
                            </div>
                            <p></p>
                            <div class="row">
                            <div class="col-lg-12 col-md-12">
                            <label>Image</label>
                            <input type="file" accept="image/jpeg/png/jpg" @change=uploadImage class="form-control" >
                            </div>
                           
                            </div>
                            <div style="float:left">
                              <button class="default-btn"  style="width: 200px;margin-top:12px; margin-left: 14px;" @click="updateProfile()" >Update</button>
                              <b-alert style="float: right;margin-top:12px;margin-left:7px;" fade :variant="alert_class_name" v-model="showAlert">
                                    {{alertMessage}}
                                </b-alert> 
                            </div>
                            </div>
                            
                            &nbsp;
                             
                            
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
    </div>
</template>

<script>
import * as api from './../../apis/authapi.js';
import * as auth from './../../helpers/authHelper.js';
import moment from "moment";
import myDatepicker from 'vue-datepicker';
import router from "../../router";

export default {
    name: 'Profile',
     data() {
    return {      
      alert_class_name: "",      
      showAlert: false,
      alertMessage: 'This is the alert message',            
      UserData:{
          user_uid:'',
          username:'',
          phone_number:'', 
          email:'',
          profile_image:''         
      },           
    };
  },
  mounted() {        
    let Token_Name = localStorage.getItem("jwt");
    if (Token_Name != null) {
      this.userDetails = localStorage.getItem("userDetails");      
      this.userDetails = JSON.parse(this.userDetails);      
      this.isSession = true;
    }  
    this.getUserDetail();
  },
  methods: {
      uploadImage(e){
        const selectedImage = e.target.files[0];
        this.createBase64Image(selectedImage);
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.UserData.profile_image = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
      async getUserDetail(){
        var data = {
            user_uid:this.userDetails.user_uid,        
        };
        var response =await api.userbyid(data, this.router);
             if(response.data.status==true && response.data.msg=="User Details Found" ){
                this.UserData.username = response.data.data.username;                                
                this.UserData.phone_number = response.data.data.phone_number;
                this.UserData.email = response.data.data.email;                
                this.UserData.user_uid = response.data.data.user_uid;
                this.UserData.profile_image = response.data.data.profile_image;   
                
                if(this.UserData.profile_image==null || this.UserData.profile_image==''){
                  this.UserData.profile_image = "../../assets/img/gym/pImg.png";
                }
                
             }else{                 
                 router.push({ name: 'AuthenticationPage' });
             }
        },
        async updateProfile() {
          let validation = 0
                if(this.UserData.username==null || this.UserData.username==''){                  
                  this.delayedAlert();
                  this.alertMessage = "Username is Required.";
                  this.alert_class_name = "danger";   
                  validation = 1;
                } 

                if(validation==0){                  
                  var ss=  await api.updateProfile(this.UserData,this.router);
                  if(ss.statuscode==1){
                    this.delayedAlert();
                    this.alertMessage = "Record Saved Successfully.";
                    this.alert_class_name = "success";   
                  }else{
                    this.delayedAlert();
                    this.alertMessage = ss.message;
                    this.alert_class_name = "danger";   
                  }
                }
                
            },
        delayedAlert() {     
                this.showAlert = true;
                setTimeout(() => {
                    this.showAlert = false;
                }, 5000);
            }, 
  }
}
</script>