import axios from 'axios'
import * as constant from '../config/constant.js'

export const getPlanList = async () => {
    var newss = []
    var token = localStorage.getItem('jwt')
    const res = await axios.get(`${constant.base_url}/plans`, {
      headers: {
        token,
      },
    })
    var finalRes = res.data.data
    var count = 0
    finalRes.forEach((items) => {
      ++count
      let obj = {
        id: items.id,
        plan_name: items.plan_name, 
        plan_price: items.plan_price, 
      }
      newss.push(obj)
    })
    return newss
  }
