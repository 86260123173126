<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="contact-info">
                        <span class="sub-title">Email Verification</span>
                        <h2>Account Verified</h2>
                        <p>{{message}}</p>

                    </div>
                    
                </div>
                <div class="col-lg-12 col-md-12" v-if="this.status==true">
                        <button type="submit"  @click="loginPage()" class="default-btn">Login<span></span></button>
                    </div>
                <!-- <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h2>Reset Your Password</h2>
                        <p>Enter New Password</p>
                        <form id="contactForm" @submit.prevent>
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group mb-3">
                                        <input v-model="password" type="password" name="passwod" id="password" placeholder="New Password">
                                    </div>
                                </div>                                
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit"  @click="resetPassword()" class="default-btn">Submit<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router'
import vueRecaptcha from 'vue3-recaptcha2';
import * as api from './../../apis/authapi.js';
import * as auth from './../../helpers/authHelper.js';
import router from "../../router";
export default {
    name: 'Contact',
    data() {
            const router = useRouter();            
            const password = '';
            const userid = '';            
            return {
                router,                
                password,
                userid,
                message:'',
                status:''    
            }
        },
        mounted() {
            this.verfied();
        },
    methods: {
        async verfied() {
            this.userid = this.$route.params.id;            
            console.log('Verified submitted');
            var ss=await api.emailVerified(this.userid,this.router);
            console.log(ss.data.status);
            if(ss.data.status==true){
                this.status =true;
                this.message = 'Thank You, Your email has been verified. Your account is now active. Please use the link to below to login to your account.';
            }else{
                this.status =false;
                this.message = 'Sorry, Your email has not beed verified. Please contact admin.'
            }
        },
        loginPage() {
                router.push({ name: 'AuthenticationPage' });
            },
    },
}
</script>