<template>
    <div class="featured-area" style="margin-top: 46px;">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-featured-box">
                        <img src="../../assets/img/featured/f2.png" alt="image">
                        <div class="content">
                            <i class="icon bx bx-run"></i>
                            <h3>MY RUN MY PACE</h3>
                            <p>Receive a personalized training plan tailored to your specific health and fitness needs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-featured-box">
                        <img src="../../assets/img/featured/f11.png" alt="image">
                        <div class="content">
                            <i class="icon flaticon-shield-1"></i>
                            <h3>LEARN FROM EXPERT</h3>
                            <p>Our extensive training experience ensures the best results for you.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-featured-box">
                        <img src="../../assets/img/featured/f3.png" alt="image">
                        <div class="content">
                            <i class="icon flaticon-world"></i>
                            <h3>PRACTICE FROM ANYWHERE</h3>
                            <p>Our online training plan is designed to accommodate every runner from any location.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Featured'
}
</script>