<template>
  <div :class="['navbar-area', { 'is-sticky': isSticky }]">
    <div class="vdemy-nav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link class="navbar-brand" to="/">
            <img src="../../assets/img/logo.png" alt="logo" style="width: 95px;"/>
          </router-link>
          <div
            class="navbar-toggler"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }"
            v-on:click="button_active_state = !button_active_state"
          >
            <i class="bx bx-menu"></i>
            <i class="bx bx-x"></i>
          </div>
          <div class="collapse navbar-collapse" :class="{ toggler: active }">
            <!-- <form class="search-box" @submit.prevent>
              <input type="text" class="input-search" placeholder="Search" />
              <button type="submit"><i class="flaticon-search"></i></button>
            </form> -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/" class="nav-link"> Home</router-link>
                <!-- <a href="/mpa" class="nav-link">
                  Home
                 
                </a> -->
                <!-- <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link"
                      >eLearning School</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/vendor-certification" class="nav-link"
                      >Vendor Certification eTraining</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/online-training" class="nav-link"
                      >Online Training School</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/distance-learning" class="nav-link"
                      >Distance Learning</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/language-school" class="nav-link"
                      >Language School</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/modern-schooling" class="nav-link"
                      >Modern Schooling</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/yoga-training" class="nav-link"
                      >Yoga Training</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/health-coaching" class="nav-link"
                      >Health Coaching</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/kindergarten" class="nav-link"
                      >Kindergaten</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/gym-coaching" class="nav-link"
                      >Gym Coaching</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/learning-management" class="nav-link"
                      >Learning Management</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/business-coach" class="nav-link"
                      >Business Coach</router-link
                    >
                  </li>
                </ul> -->
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link">
                  MPA
                  <i class="bx bx-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                <!--  <li class="nav-item">
                    <a href="#" class="nav-link">
                      About Us
                      <i class="bx bx-chevron-right"></i>
                    </a>
                    <ul class="dropdown-menu">
                       <li class="nav-item">
                        <router-link to="/about-one" class="nav-link"
                          >About Us</router-link
                        >
                      </li> 
                       <li class="nav-item">
                        <router-link to="/about-two" class="nav-link"
                          >About Us 02</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/about-three" class="nav-link"
                          >About Us 03</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/about-four" class="nav-link"
                          >About Us 04</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/about-five" class="nav-link"
                          >About Us 05</router-link
                        >
                      </li> 
                    </ul>
                  </li>-->
                  <li class="nav-item">
                    <router-link to="/join" class="nav-link"
                      >Join Us </router-link
                    >
                  </li>
                  <!-- <li class="nav-item">
                    <router-link to="/become-a-memeber"
                      >Become a Member</router-link
                    >
                  </li> -->
                  <li class="nav-item">
                    <router-link to="/success-story" class="nav-link"
                      >Success Story</router-link
                    >
                  </li>
                  <!-- <li class="nav-item">
                    <router-link to="/advisor" class="nav-link"
                      >Teacher</router-link
                    >
                  </li> -->
                  <li class="nav-item">
                    <router-link to="/trainer" class="nav-link"  
                      >Trainer</router-link
                    >
                    <!-- <router-link :to="{ path: '/trainer'}" class="nav-link" 
                      >Trainer</router-link
                    > -->
                  </li>
                  <!-- <li class="nav-item">
                    <router-link to="/gallery" class="nav-link"
                      >Gallery</router-link
                    >
                  </li> -->
                  <li class="nav-item">
                    <router-link to="/faq" class="nav-link">FAQ</router-link>
                  </li>
                  <li class="nav-item">
                        <router-link to="/about-us" class="nav-link"
                          >About Us</router-link
                        >
              </li>
                  <!-- <li class="nav-item">
                    <router-link to="/contact" class="nav-link"
                      >Contact Us</router-link
                    >
                  </li> -->
                 
                  <!-- <li class="nav-item">
                    <router-link to="/coming-soon" class="nav-link"
                      >Coming Soon</router-link
                    >
                  </li> -->
                  
                  <!-- <li class="nav-item">
                    <router-link to="/privacy-policy" class="nav-link"
                      >Privacy Policy</router-link
                    >
                  </li> -->
                  <!-- <li class="nav-item">
                    <router-link to="/terms-of-service" class="nav-link"
                      >Terms of Service</router-link
                    >
                  </li> -->
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/newsmedia" class="nav-link" > News & Media</router-link>
                 <!-- <a href="/events" class="nav-link">
                  News & Media
                 <i class="bx bx-chevron-down"></i> 
                </a>-->
                <!-- <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/events" class="nav-link"
                      >Events</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/single-events" class="nav-link"
                      >Events Details</router-link
                    >
                  </li>
                </ul> -->
              </li>
              <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  Join
                  <i class="bx bx-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link to="/mpa"
                      >MPA </router-link
                    >
                  </li>
                  <li>
                    <router-link to="/become-a-memeber"
                      >Become a Member</router-link
                    >
                  </li>
                
                </ul>
              </li> -->
              <li class="nav-item">
                <router-link to="/gallery" class="nav-link">Photo  Gallery</router-link>
                 <!-- <a href="/events" class="nav-link">
                  News & Media
                 <i class="bx bx-chevron-down"></i> 
                </a>-->
                <!-- <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/events" class="nav-link"
                      >Events</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/single-events" class="nav-link"
                      >Events Details</router-link
                    >
                  </li>
                </ul> -->
              </li>
              <!-- <li class="nav-item">
                <router-link to="/gallery"
                      >Photo Gallery</router-link
                    >
             
              </li> -->
              <!-- <li class="nav-item megamenu">
                <a href="/#" class="nav-link">
                  Courses
                  <i class="bx bx-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <ul class="megamenu-submenu">
                            <li>
                              <router-link to="/courses-one"
                                >Courses Grid 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-two"
                                >Courses Grid 02</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-three"
                                >Courses Grid 03</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-four"
                                >Courses Grid 04</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-five"
                                >Courses List 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-six"
                                >Courses Right Sidebar</router-link
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="col">
                          <ul class="megamenu-submenu">
                            <li>
                              <router-link to="/single-courses-one"
                                >Single Layout 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/single-courses-two"
                                >Single Layout 02</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/categories"
                                >Courses Categories</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/membership-levels"
                                >Membership Levels</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/become-a-teacher"
                                >Become a Teacher</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/profile">Profile</router-link>
                            </li>
                          </ul>
                        </div>
                        <div class="col">
                          <ul class="megamenu-submenu">
                            <li>
                              <router-link to="/courses-one"
                                >Courses Grid 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-two"
                                >Courses Grid 02</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-three"
                                >Courses Grid 03</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-four"
                                >Courses Grid 04</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-five"
                                >Courses List 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/courses-six"
                                >Courses Right Sidebar</router-link
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="col">
                          <ul class="megamenu-submenu">
                            <li>
                              <router-link to="/single-courses-one"
                                >Single Layout 01</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/single-courses-two"
                                >Single Layout 02</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/categories"
                                >Courses Categories</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/membership-levels"
                                >Membership Levels</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/become-a-teacher"
                                >Become a Teacher</router-link
                              >
                            </li>
                            <li>
                              <router-link to="/profile">Profile</router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bx-code-alt"></i>
                            </div>
                            <h3>Development</h3>
                            <span class="sub-title">60 Courses</span>
                            <router-link
                              to="/courses-one"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bx-camera"></i>
                            </div>
                            <h3>Photography</h3>
                            <span class="sub-title">21 Courses</span>
                            <router-link
                              to="/courses-two"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bx-layer"></i>
                            </div>
                            <h3>Design</h3>
                            <span class="sub-title">58 Courses</span>
                            <router-link
                              to="/courses-three"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bxs-flag-checkered"></i>
                            </div>
                            <h3>Language</h3>
                            <span class="sub-title">99 Courses</span>
                            <router-link
                              to="/courses-four"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bx-health"></i>
                            </div>
                            <h3>Fitness</h3>
                            <span class="sub-title">21 Courses</span>
                            <router-link
                              to="/courses-five"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-4 col-md-4 col-6 p-0">
                          <div class="single-category-widget">
                            <div class="icon">
                              <i class="bx bx-line-chart"></i>
                            </div>
                            <h3>Business</h3>
                            <span class="sub-title">49 Courses</span>
                            <router-link
                              to="/courses-six"
                              class="link-btn"
                            ></router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li> -->

              <!-- <li class="nav-item">
                <router-link to="/running" class="nav-link"
                      >Running Events</router-link
                    >
              </li> -->
              <li class="nav-item">
                    <router-link to="/contact" class="nav-link"
                      >Contact Us</router-link
                    >
                  </li>
              <!-- <li class="nav-item">
                        <router-link to="/about-one" class="nav-link"
                          >About Us</router-link
                        >
              </li> -->
              <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  Shop
                  <i class="bx bx-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/products-list-one" class="nav-link"
                      >Product List 01</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/products-list-two" class="nav-link"
                      >Product List 02</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/cart" class="nav-link">Cart</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/checkout" class="nav-link"
                      >Checkout</router-link
                    >
                  </li>
                </ul>
              </li> -->
              <!-- <li class="nav-item">
                <a href="#" class="nav-link">
                  Blog
                  <i class="bx bx-chevron-down"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <router-link to="/blog-one" class="nav-link"
                      >Grid (2 in Row)</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-two" class="nav-link"
                      >Grid (3 in Row)</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-three" class="nav-link"
                      >Grid (Full Width)</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/blog-four" class="nav-link"
                      >Right Sidebar</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      Single Post
                      <i class="bx bx-chevron-right"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <router-link to="/single-blog-one" class="nav-link"
                          >Default</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/single-blog-two" class="nav-link"
                          >With Video</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link to="/single-blog-three" class="nav-link"
                          >With Image Slider</router-link
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>

          <div class="others-option d-flex align-items-center">
            <div class="option-item">
              <!-- <div class="cart-btn">
                <router-link to="/cart">
                  <i class="flaticon-shopping-cart"></i>
                  <span>{{ shoppingCart.length }}</span>
                </router-link>
              </div> -->
            </div>
           
            <div class="option-item">
              <router-link v-if="!isSession" to="/login" class="default-btn" id="loginBtnClass" >
                <i class="flaticon-user"></i>
                Login              
              </router-link>
            
              
              <!-- <button v-else v-on:click="logout()" class="default-btn">
                <i class="flaticon-user"></i>
                {{userDetails.name}}
                <span></span>
              </button> -->
              <div class="dropdown" v-else>
              <button class="default-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="flaticon-user"></i> {{userDetails.name}}         
              </button>
              <div class="dropdown-menu">
                <router-link to="/profile" class="dropdown-item">Profile</router-link>
                <router-link to="/dashboard" class="dropdown-item">Dashboard</router-link>
                <!-- <a class="dropdown-item" href="#">Change Password</a> -->
                <a class="dropdown-item" href="#" v-on:click="logout()">Logout</a>
              </div>
            </div>
            <p v-if="!isSession"  class="pText">Don't have an account? <router-link to="/signup" class="signUpColor"> &nbsp;Sign Up</router-link></p> 
                                   
            <!-- <span>&nbsp;Don't have an account ?  <a href="#">Register</a> </span> -->
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>


<script>
  import { defineComponent } from 'vue';
  import * as api from './../../apis/authapi';
  import { Sdk } from '../Authentication/facebook/Sdk';
  import Scope from '../Authentication/facebook/Scope';
  export default defineComponent ({
  name: "Navbar",
  components: {    
    Scope,    
  },
  data() {
    const  isSession=false;
    const userDetails='';
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
      isSession,
      userDetails
    };
  },
  mounted() {
    let Token_Name = localStorage.getItem('jwt');
    if(Token_Name != null)
    {
      this.userDetails = localStorage.getItem('userDetails')
      console.log(this.userDetails);
      this.userDetails = JSON.parse(this.userDetails)
      console.log(this.userDetails);      
      this.isSession =true;
    }
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
  computed: {
    shoppingCart() {
      return this.$store.state.cart;
    },
  },
  methods: {
    
    formSubmit() {
      console.log('form submitted');
    },
    logout() {      
      // api.logout();
      // this.$router.go();
      // console.log('logout clicked');
      if(this.userDetails.login_with==3){
        this.fblogout();
        api.logout();
        this.$router.go();
        console.log('logout clicked');        
      }else{
        api.logout();
        this.$router.go();
        console.log('logout clicked');
      }
    },
    refreshPage() {
      this.$router.go();
    },
    async fblogout() {      
        const response = await Sdk.getLoginStatus()
        if (Sdk.isConnected(response.status)) await Sdk.logout()
        this.connected = false
        this.$emit('logout', response)        
        //return response      
    },
    
  },
});
</script>
<style>
.dropdown-item.active, .dropdown-item:active{
  background-color: #004aad !important;
}
.dropdown-menu{
  min-width: 9rem;
}
.pText{
  display: flex;
}
#loginBtnClass{
  margin-left: 99px;
}
@media only screen and (max-width: 767px) {

.pText{
  display: inherit;
    margin: 0 auto;
    width: 59%;
    text-align: center;
}
.signUpColor{
  color: blue;
}

}
</style>