<template>
    <div>
        <Navbar />
        <!-- <PageTitle pageTitle="Dashboard" /> -->
        <UserDashboard />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import UserDashboard from '../UserDashboard/UserDashboard'
import Footer from '../Layouts/Footer'

export default {
    name: 'UserDasboardPage',
    components: {
        Navbar,
        PageTitle,
        UserDashboard,
        Footer,
    },
}
</script>