<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Lost Password" />
        <ResetPassword />        
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import ResetPassword from '../LostPassword/LostPassword'

import Footer from '../Layouts/Footer'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageTitle,
        ResetPassword,        
        Footer,
    },
}
</script>