<template>
    <div class="contact-area ptb-100">
        <div class="container">

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div id="map">
                    <p><b>Atal Khel Parisar</b></p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1839.4727628024923!2d75.88988339839479!3d22.76740280000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303f99259f3ff%3A0xee216d154f3c122a!2sAtal%20ground!5e0!3m2!1sen!2sin!4v1685358760394!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div id="map">
                    <p><b>Nehru Stadium</b></p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1840.257308456305!2d75.87897053868964!3d22.7091061448487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd23eea4d7e5%3A0xdd4d7f5b204e8c5b!2sJawaharlal%20Nehru%20Stadium!5e0!3m2!1sen!2sin!4v1685358912136!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'Map'
}
</script>