import axios from 'axios'
import moment from 'moment'
import { useRoute } from 'vue-router'
import * as constant from '../config/constant.js'
export const eventreg = async (data) => {    
   const res=await axios.post(`${constant.base_url}/eventreg`,data)
      .then((response) => {
        if (response.data.status == true) {          
            var  res = response.data;
       return res;
        } else {            
        var  res = response.data;
        return res;
        }
        
      })
      //console.log(res);
      return res;  
  }
export const eventlist = async () => {
    var events = []
    var token = localStorage.getItem('jwt')
    const res = await axios.get(`${constant.base_url}/events`, {
      headers: {
        token,
      },
    })
    console.log(res.data.data)
    var finalRes = res.data.data
    var count = 0
    finalRes.forEach((items) => {
      ++count
      var date = moment(items.date_time).format('dddd, DD MMM, YYYY hh:mm')
      var key=moment(items.date_time).isBefore(moment())?'expired':'upcoming';
      console.log(items.date_time);
      console.log(moment().isBefore(moment(items.date_time)));
      console.log(key);
      let obj = {
          id: items.id,
          image: constant.base_url_media+'/'+items.filepath, 
          date,
          title: items.name,
          location: items.place,
          key
      }
      events.push(obj)
    })
    return events
  }

  export const eventbyid = async (req,res) => {    
    console.log(req);    
    const response = await axios.get(`${constant.base_url}/event/`+req.event_id, {
      headers: {        
      },
    })    
    return response;
  } 