<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Profile" />
        <Profile />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Profile from '../Profile/Profile'
import Footer from '../Layouts/Footer'

export default {
    name: 'ProfilePage',
    components: {
        Navbar,
        PageTitle,
        Profile,
        Footer,
    },
}
</script>