<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Event Details" />
        <EventsDetails />
        <EventParticipants />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import EventsDetails from '../EventsDetails/EventsDetails'
import EventParticipants from '../EventsDetails/EventParticipants'
import Footer from '../Layouts/Footer'

export default {
    name: 'EventsDetailsPage',
    components: {
        Navbar,
        PageTitle,
        EventsDetails,
        EventParticipants,
        Footer,
    },
}
</script>