<template>
    <div class="why-choose-us-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="why-choose-us-image">
                        <img src="../../assets/img/gym/v1.png" alt="image" style="height: 384px;transform: scale(1.5);
                            ">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="why-choose-us-content">
                        <span class="sub-title">WHY CHOOSE US ?</span>
                        <h2>BUILD RUNNING SKILLS WITH EXPERTS </h2>
                        <p>Elevate your running abilities by learning from MPA experts trainer. Gain valuable skills and knowledge to enhance your running performance. Unlock your potential with expert guidance in building a strong foundation for successful running.</p>
                        <ul class="features-list">
                            <li><span><i class="flaticon-experience"></i> Full Marathon</span></li>
                            <li><span><i class="flaticon-time-left"></i> Half Marathon</span></li>
                            <li><span><i class="flaticon-tutorials"></i> Ultra Run</span></li>
                            <li><span><i class="flaticon-self-growth"></i> Comrades Marathon</span></li>
                        </ul>
                        <router-link to="/join" class="default-btn">
                            <i class="flaticon-user"></i>
                            Join Us
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>
<style>
@media only screen and (max-width: 767px)
{
    .why-choose-us-content {
        margin-top: 66px;
    }
}
</style>
