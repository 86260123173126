<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Success Story" />
        <SuccessStory />
        <Testimonials />
        <OurStory />
        <StartWithSuccess />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import SuccessStory from '../SuccessStory/SuccessStory'
import Testimonials from '../SuccessStory/Testimonials'
import OurStory from '../SuccessStory/OurStory'
import StartWithSuccess from '../SuccessStory/StartWithSuccess'
import Footer from '../Layouts/Footer'

export default {
    name: 'SuccessStoryPage',
    components: {
        Navbar,
        PageTitle,
        SuccessStory,
        Testimonials,
        OurStory,
        StartWithSuccess,
        Footer,
    },
}
</script>