<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Gallery" />
        <Gallery />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Gallery from '../Gallery/Gallery'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'GalleryPage',
    components: {
        Navbar,
        PageTitle,
        Gallery,
        Subscribe,
        Footer,
    },
}
</script>